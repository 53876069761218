import { PaymentAction } from "../actions/paymentActions";

const initialState = {
    activeSubscription: undefined,
};

const paymentReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    switch (type) {
        case PaymentAction.UpdateActiveSubscription:
        case PaymentAction.UpdateExchangeRates:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default paymentReducer;
