export const MenuAction = {
    SetShowTopNavBar: "SET_SHOW_TOP_NAVBAR",
    SetLoaderOverlayVisiblity: "SET_LOADER_OVERLAY_VISIBILITY",
    SetShowBanner: "SET_SHOW_BANNER",
    SetBannerHeight: "SET_BANNER_HEIGHT",
};

export const setShowNavbar = (showTopNavbar, dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: MenuAction.SetShowTopNavBar,
            payload: { showTopNavbar },
        });
        resolve();
    });
};
export const setLoaderOverlayVisiblity = (isLoaderOverlayVisible, dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: MenuAction.SetLoaderOverlayVisiblity,
            payload: { isLoaderOverlayVisible },
        });
        resolve();
    });
};

export const setShowBanner = (showBanner, dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: MenuAction.SetShowTopNavBar,
            payload: { showBanner },
        });
        resolve();
    });
};

export const setBannerHeight = (bannerHeight, dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: MenuAction.SetBannerHeight,
            payload: { bannerHeight },
        });
        resolve();
    });
};
