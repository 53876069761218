import { postcodeValidator, postcodeValidatorExistsForCountry } from "postcode-validator";
import environment from "@src/environment";
import { initializePaddle } from "@paddle/paddle-js";
import axios from "axios";
import PaymentsService from "services/payments.service.js";
import { CURR_WITHOUT_LOWER_DENOM } from "@common/constants/constants";

let paddleInstance = null;

export const getPaddleInstance = async () => {
    if (!paddleInstance) {
        paddleInstance = await initPaddle();
    }
    return paddleInstance;
};

export const parseFeatures = (features) => {
    const map = {};
    features.forEach((feature) => (map[feature.name] = feature));
    return map;
};

/* istanbul ignore next */
export const zipCodeValidation = (value, country) => {
    if (value && country && postcodeValidatorExistsForCountry(country))
        return postcodeValidator(value, country);
    else return true;
};

export const extractFreePlan = (plans) => {
    const freePlanIndex = plans.findIndex((plan) => plan._id === environment.FREE_PLAN_ID);
    const freePlan = plans[freePlanIndex];
    delete plans[freePlanIndex];
    return freePlan;
};

export const separatePlansByInterval = (plans) => {
    const yearlyPlans = [];
    const monthlyPlans = [];
    plans.forEach((plan) => {
        if (plan.interval.startsWith("yearly")) yearlyPlans.push(plan);
        else monthlyPlans.push(plan);
    });

    return { monthlyPlans, yearlyPlans };
};
export const getPlanDetails = (plans) => {
    plans.map((plan) => {
        plan.features = parseFeatures(plan?.features);
    });
    const freePlan = extractFreePlan(plans);
    const separatedPlans = separatePlansByInterval(plans);
    const yearlyPlans = sortByAmount(separatedPlans.yearlyPlans);
    const monthlyPlans = sortByAmount(separatedPlans.monthlyPlans);
    return { freePlan, yearlyPlans, monthlyPlans };
};

export const sortByAmount = (items) => {
    items.sort(function (a, b) {
        return parseFloat(a.amount) - parseFloat(b.amount);
    });
    return items;
};

/**
 * Format price as per currency
 * @param {String|Number} total - price in the given currency
 * @param {String} currency
 * @param {Boolean} inLowestDenomination - whether the price is in lowest denomination of currency
 * @returns
 */
export const formatPrice = (
    total,
    currency,
    inLowestDenomination = false,
    maxFractionDigits = 2,
) => {
    if (!total) return "";

    total = typeof total === "string" ? parseFloat(total) : total;
    if (inLowestDenomination && !CURR_WITHOUT_LOWER_DENOM.includes(currency.toUpperCase()))
        total = total / 100; // convert to higher denomination

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        maximumFractionDigits: maxFractionDigits,
    }).format(total);
};

export const getAddonDetails = (addons) => {
    addons.map((addon) => {
        addon.features = parseFeatures(addon?.features);
    });
    return sortByAmount(addons);
};

const fetchBillingOrIpAddress = async (orgId) => {
    try {
        const billingAddress = orgId ? await PaymentsService.getCustomerAddress(orgId) : null;

        let ipAddress;
        if (!billingAddress?.data) {
            ipAddress = await axios.get("https://api.ipify.org?format=json", {
                withCredentials: false,
            });
        }

        return { billingAddress: billingAddress?.data, ipAddress: ipAddress?.data?.ip };
    } catch (err) {
        console.log(err);
    }
};

const initPaddle = async () => {
    try {
        const initObj = {
            token: environment.PADDLE_CLIENT_SIDE_TOKEN,
        };

        if (environment.ENV !== "production") {
            initObj.environment = "sandbox";
        }

        return initializePaddle(initObj);
    } catch (err) {
        console.log(err);
    }
};

export const paddlePricePreview = async (planOrAddons, orgId) => {
    const paddle = await getPaddleInstance();
    const { billingAddress, ipAddress } = (await fetchBillingOrIpAddress(orgId)) || {};

    const priceIdList = planOrAddons
        .filter((planOrAddon) => planOrAddon._id !== environment.FREE_PLAN_ID)
        .map((planOrAddon) => ({ quantity: 1, priceId: planOrAddon.metadata?.paddlePriceId }));

    const paddlePayload = {
        items: priceIdList,
        ...(billingAddress
            ? { address: { countryCode: billingAddress.addressCountry } }
            : { customerIpAddress: ipAddress }),
    };

    return paddle.PricePreview(paddlePayload);
};

export const fetchPricesFromPaddle = async (planOrAddons, orgId) => {
    try {
        const result = await paddlePricePreview(planOrAddons, orgId);
        const prices = result.data.details.lineItems;
        const currencyCode = result.data.currencyCode;

        const priceMap = {};
        const denominator = CURR_WITHOUT_LOWER_DENOM.includes(currencyCode.toUpperCase()) ? 1 : 100;

        prices.forEach((price) => {
            priceMap[price.price.id] = {
                price:
                    price.price.billingCycle?.interval === "year"
                        ? parseFloat(price.totals.subtotal) / (denominator * 12)
                        : parseFloat(price.totals.subtotal) / denominator,
            };
        });

        planOrAddons.forEach((planOrAddon) => {
            const priceInfo = priceMap[planOrAddon.metadata?.paddlePriceId] || {};
            planOrAddon.price = priceInfo.price || planOrAddon.amount;
            planOrAddon.currencyCode = currencyCode || "USD";
        });
    } catch (err) {
        planOrAddons.forEach((plan) => {
            plan.price = plan.amount;
            plan.currencyCode = "USD";
        });
    }
};

export const fetchAllPlansAndAddons = async (opts) => {
    const fetchItems = async (fetchFn, page) => {
        const res = await fetchFn(opts, page);
        const items = res?.data?.items || [];
        const hasNext = res?.data?.page?.hasNext || false;
        return { items, hasNext };
    };

    const fetchAll = async (fetchFn) => {
        let allItems = [];
        let page = 1;
        let hasNext = true;

        while (hasNext) {
            const { items, hasNext: nextPage } = await fetchItems(fetchFn, page);
            allItems = [...allItems, ...items];
            hasNext = nextPage;
            page++;
        }

        return allItems;
    };

    const [allPlans, allAddons] = await Promise.all([
        fetchAll(PaymentsService.getPlans),
        fetchAll(PaymentsService.getAddOns),
    ]);

    return { allPlans, allAddons };
};

export const getPlansAddons = async (opts, orgId) => {
    try {
        const { allPlans: plans, allAddons: addons } = await fetchAllPlansAndAddons(opts);
        const plansAddons = [...plans, ...addons];

        await fetchPricesFromPaddle(plansAddons, orgId);

        const separatedPlans = getPlanDetails(plans);
        const addOns = getAddonDetails(addons);

        return { separatedPlans, addOns };
    } catch (err) {
        console.log(err);
    }
};
