import ZoneService from "../../../services/zone.service";

export const ZoneAction = {
    ZoneCreateSuccess: "CREATE_ZONE_SUCCESS",
    ZoneCreateFailed: "CREATE_ZONE_FAILED",
    CreateZone: "CREATE_ZONE",
    GetZones: "GET_ZONES",
    GetZone: "GET_ZONE",
    UpdateZone: "UPDATE_ZONE",
    DeleteZone: "DELETE_ZONE",
};

export const createZone = (orgId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        ZoneService.createZone(orgId, data)
            .then((res) => {
                dispatch({
                    type: ZoneAction.ZoneCreateSuccess,
                    payload: { isZoneRegistered: true, zone: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ZoneAction.ZoneCreateFailed,
                    payload: { isZoneRegistered: false, zone: null },
                });
                reject(err);
            });
    });
};

export const getZones = (dispatch, orgId, pageNo, pageSize, search) => {
    return new Promise((resolve, reject) => {
        ZoneService.getZones(orgId, pageNo, pageSize, search)
            .then((res) => {
                dispatch({
                    type: ZoneAction.GetZones,
                    payload: { zoneList: { zones: res.data.items, page: res.data.page } },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ZoneAction.GetZones,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};

export const updateZone = (orgId, zoneId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        ZoneService.updateZone(orgId, zoneId, data)
            .then((res) => {
                dispatch({
                    type: ZoneAction.UpdateZone,
                    payload: { isZoneUpdated: true, zone: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ZoneAction.UpdateZone,
                    payload: { isZoneUpdated: false, zone: null },
                });
                reject(err);
            });
    });
};

export const getZone = (orgId, zoneId, dispatch) => {
    return new Promise((resolve, reject) => {
        ZoneService.getZone(orgId, zoneId)
            .then((res) => {
                dispatch({
                    type: ZoneAction.GetZone,
                    payload: { exists: true, zone: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ZoneAction.GetZone,
                    payload: { exists: false, zone: null },
                });
                reject(err);
            });
    });
};

export const deleteZone = (dispatch, orgId, zoneId) => {
    return new Promise((resolve, reject) => {
        ZoneService.deleteZone(orgId, zoneId)
            .then((res) => {
                dispatch({
                    type: ZoneAction.DeleteZone,
                    payload: { exists: true, zone: zoneId },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ZoneAction.DeleteZone,
                    payload: { exists: false, zone: null },
                });
                reject(err);
            });
    });
};
