import { UploadAction } from "../actions/uploadActions";
import { SUB_HEADING_TAB } from "../../components/UploadNotification/data";

const initialState = {
    uploadProgress: null,
    successFiles: null,
    failedFiles: null,
    getFilteredFiles: null,
    isVisible: true,
    scrollTopVisible: false,
    activeTab: SUB_HEADING_TAB[0],
    statusCount: {
        totalCount: 0,
        successCount: 0,
        failedCount: 0,
        cancelledCount: 0,
    },
};

const uploadReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    switch (type) {
        case UploadAction.SetUploadNotiTab:
            return {
                ...state,
                ...{ activeTab: payload },
            };
        case UploadAction.UpdateUploadProgress:
        case UploadAction.UploadSuccess:
        case UploadAction.UploadFailed:
        case UploadAction.RefetchFiles:
        case UploadAction.ClearUploadNotification:
        case UploadAction.CancelUpload:
        case UploadAction.CancelAllUpload:
        case UploadAction.UpdateStatusCount:
        case UploadAction.UpdateScrollTopVisible:
        case UploadAction.UpdateNotificationVisibilty:
        case UploadAction.RetryStarted:
        case UploadAction.RetryAllUploadStarted:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default uploadReducer;
