import React from "react";
import { Breakpoints } from "@src/pages/Playground/styles/PlaygroundStyle";
import styled from "styled-components";
import UnknownCard from "common/assets/svgs/Visa.svg";
import { CardIcons } from "./CardIcons";
import Button from "@common/components/Button";
import PlusIcon from "@common/assets/PlusIcon";
import { EventEmitter } from "@common/events/eventEmitter";
import { ADD_CARD_INTIATED } from "@common/events/events";
import { billingConstants } from "../constants";

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    width: 100%;
    overflow-y: auto;

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 10px 0px;
    }
`;

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--border-radius-8);
    margin: 10px 0px;
    padding: 5px;
    border: 2px solid rgba(219, 222, 255, 0.16);
    cursor: pointer;
    @media (max-width: ${Breakpoints.mobile}px) {
        /* height: 5px; */
    }
    /* width: 70%; */
`;

const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    /* margin: 10px; */
`;

const CardCheckWrapper = styled.div`
    height: 24px;
    width: 24px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        cursor: pointer;
    }
`;

const CardIcon = styled.img`
    width: 45px;
    height: 45px;
    margin-right: 10px;
`;
const CardNumber = styled.span`
    font-size: var(--body-1-d);
    color: var(--light-100);
`;

const EachCard = ({
    cardList,
    setSelectedCard,
    selectedCard,
    showAddCardDialog,
    customerAddress,
    selectedItem,
}) => {
    const handlAddCardClick = () => {
        showAddCardDialog();
        const credits = selectedItem.features?.credits?.quantity;
        const isYearly = selectedItem.interval === billingConstants.INTERVALS.YEARLY;

        EventEmitter.dispatch(ADD_CARD_INTIATED, {
            amount: selectedItem.amount * (isYearly ? 12 : 1),
            credits,
        });
    };
    return (
        <DetailsWrapper>
            {cardList?.map((card) => {
                return (
                    <Cards
                        key={`${card._id}`}
                        onClick={() => {
                            setSelectedCard(card);
                        }}
                    >
                        <LeftWrapper>
                            <CardCheckWrapper>
                                <input type="radio" checked={selectedCard?._id === card?._id} />
                            </CardCheckWrapper>
                            <CardIcon src={CardIcons[card.brand] || UnknownCard} alt="cards" />
                            <CardNumber>********{card.last4}</CardNumber> &nbsp; &nbsp; &nbsp;
                            <CardNumber style={{ fontStyle: "italic" }}>
                                {card.expMonth}/{card.expYear}
                            </CardNumber>
                        </LeftWrapper>
                    </Cards>
                );
            })}
            <Button
                mode="secondary"
                margin="10px"
                role="add-new-card"
                disabled={customerAddress ? false : true}
                onClick={handlAddCardClick}
            >
                <PlusIcon />
                &nbsp; Add New Card
            </Button>
        </DetailsWrapper>
    );
};

export default EachCard;
