import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { billingConstants } from "../constants";
import { abbreviateNumber, capitalizeFirstLetter } from "@common/utils/utils";
import environment from "@src/environment";
import { useDispatch, useSelector } from "react-redux";
import PaymentsService from "services/payments.service.js";
import { updateExchangeRates } from "@common/redux/actions/paymentActions";
import BoxLoader from "@common/components/BoxLoader";

const BillingInfo = styled.div`
    p {
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: var(--letter-spacing);
        font-size: var(--body-1-d);
        margin-bottom: 8px;

        @media (max-width: 767px) {
            font-size: var(--body-2-d);
        }
    }
`;

const TotalAmountInfo = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    margin-top: 24px;
    padding-top: 24px;

    p {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: var(--letter-spacing);
        font-size: var(--subtitle-1-d);
        margin-bottom: 8px;

        @media (max-width: 767px) {
            font-size: var(--subtitle-2-d);
        }
    }
`;

const Label = styled.span`
    color: var(--light-200);
`;

const Value = styled.span`
    color: var(--white);
`;

const GSTSkeleton = styled.div`
    height: 25px;
`;

const StripeOrderDetails = ({ selectedItem, itemType, selectedCard = {} }) => {
    const [inr, setInr] = useState(environment.INR_CONVERSION_RATE);
    const [currencyDataLoading, setCurrencyDataLoading] = useState(true);
    const isYearly = selectedItem.interval === billingConstants.INTERVALS.YEARLY;

    const totalAmount = selectedItem.amount * (isYearly ? 12 : 1);

    let isInr = false;
    if (selectedCard?.cardCountry === billingConstants.COUNTRY_CODES.IN) {
        isInr = true;
    }

    let applyCGST = false;
    if (selectedCard?.addressState === billingConstants.SELLER_STATE) {
        applyCGST = true;
    }

    const exchangeRates = useSelector((state) => state.paymentDetails?.exchangeRates);

    const dispatch = useDispatch();

    function getRates() {
        PaymentsService.getExchangeRates(billingConstants.CURRENCY_CODES.INR)
            .then((res) => {
                updateExchangeRates(res.data, dispatch);
                setInr(res.data.inr);
            })
            .catch(console.log)
            .finally(() => setCurrencyDataLoading(false));
    }

    useEffect(() => {
        if (!exchangeRates) {
            getRates();
        } else {
            setInr(exchangeRates.inr);
            setCurrencyDataLoading(false);
        }
    }, []);

    return (
        <div>
            <BoxLoader
                stretch={true}
                loading={currencyDataLoading}
                transparent={false}
                opacity="1"
                overflow="none"
                backgroundColor="var(--dark-900)"
            >
                <BillingInfo>
                    <p>
                        <Label data-testid={"orderType"}>
                            {itemType === billingConstants.ITEM_TYPES.ADDON ? "Addon" : "Plan"}:
                        </Label>
                        <Value data-testid={"order-name"}>{selectedItem.display}</Value>
                    </p>
                    <p>
                        <Label>Credit(s):</Label>
                        <Value>{abbreviateNumber(selectedItem.features?.credits?.quantity)}</Value>
                    </p>
                    {itemType === "subscription" && (
                        <>
                            <p>
                                <Label>Storage:</Label>
                                <Value>{selectedItem.features?.storage?.quantity} GB</Value>
                            </p>
                            <p>
                                <Label>Billing:</Label>
                                <Value>{capitalizeFirstLetter(selectedItem.interval)}</Value>
                            </p>
                        </>
                    )}

                    <p>
                        <Label>Item Total:</Label>
                        {isInr ? (
                            <Value>₹{(totalAmount * inr).toFixed(2)}</Value>
                        ) : (
                            <Value>${totalAmount.toFixed(2)}</Value>
                        )}
                    </p>
                    {isInr ? (
                        applyCGST ? (
                            <>
                                <p>
                                    <Label style={{ color: "var(--light-400)" }}>SGST (9%):</Label>
                                    <Value>
                                        ₹
                                        {(
                                            (totalAmount * inr * environment.SGST_RATE) /
                                            100
                                        ).toFixed(2)}
                                    </Value>
                                </p>
                                <p>
                                    <Label style={{ color: "var(--light-400)" }}>CGST (9%):</Label>
                                    <Value>
                                        ₹
                                        {(
                                            (totalAmount * inr * environment.CGST_RATE) /
                                            100
                                        ).toFixed(2)}
                                    </Value>
                                </p>
                            </>
                        ) : (
                            <p>
                                <Label style={{ color: "var(--light-400)" }}>GST (18%):</Label>
                                <Value>
                                    ₹{((totalAmount * inr * environment.GST_RATE) / 100).toFixed(2)}
                                </Value>
                            </p>
                        )
                    ) : (
                        <GSTSkeleton></GSTSkeleton>
                    )}
                </BillingInfo>
                <TotalAmountInfo>
                    <p>
                        <Label>To Pay:</Label>
                        {isInr ? (
                            applyCGST ? (
                                /* total amount with 18% tax = total + (18% of total) = total x 1.18 */
                                <Value>
                                    ₹
                                    {(
                                        parseInt(totalAmount) *
                                        inr *
                                        (1 + (environment.CGST_RATE + environment.SGST_RATE) / 100)
                                    ).toFixed(2)}
                                </Value>
                            ) : (
                                <Value>
                                    ₹
                                    {(
                                        parseInt(totalAmount) *
                                        inr *
                                        (1 + environment.GST_RATE / 100)
                                    ).toFixed(2)}
                                </Value>
                            )
                        ) : (
                            <Value>${totalAmount.toFixed(2)}</Value>
                        )}
                    </p>
                </TotalAmountInfo>
            </BoxLoader>
        </div>
    );
};

export default StripeOrderDetails;
