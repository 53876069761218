class QueryBuilder {
    constructor(initialUrl = "") {
        const url = new URL(initialUrl);
        this.queryParams = new URLSearchParams(url.search);
        this.hashParams = url.hash;
    }

    append(key, value) {
        this.queryParams.append(key, value);
        return this;
    }

    pop() {
        const keys = Array.from(this.queryParams.keys());
        if (keys.length > 0) {
            const lastKey = keys[keys.length - 1];
            this.queryParams.delete(lastKey);
        }
        return this; // Allow method chaining
    }

    hash(value) {
        this.hashParams = value;
        return this;
    }

    toString() {
        const queryString = this.queryParams.toString();
        const hashString = this.hashParams ? `${this.hashParams}` : "";
        return queryString + hashString;
    }
}

export default QueryBuilder;
