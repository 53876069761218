import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const ChatWidgetHandler = () => {
    const { isFcWidgetLoaded } = useSelector((state) => state.userAgentDetails);
    const location = useLocation();
    const pathsToHideWidget = ["/storage", "/playground", "/checkout"];

    useEffect(() => {
        try {
            const hideWidgetOnMobileSpecificPage = () => {
                const currentPath = location?.pathname;
                const shouldHide = pathsToHideWidget.some((path) => currentPath.includes(path));
                const screenWidth = window.innerWidth;

                if (screenWidth < 768 && shouldHide) {
                    window?.fcWidget?.hide();
                } else {
                    window?.fcWidget?.show();
                }
            };
            hideWidgetOnMobileSpecificPage();
        } catch (e) {
            console.error(e);
        }
    }, [isFcWidgetLoaded, location?.pathname]);

    return null;
};

export default ChatWidgetHandler;
