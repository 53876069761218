import React from "react";

import styled from "styled-components";
import { abbreviateNumber, capitalizeFirstLetter } from "@common/utils/utils";
import LoaderIcon from "@common/components/LoaderIcon";
import { formatPrice } from "../utils";
import { billingConstants } from "../constants";
import { ITEM_TYPE } from "@src/__test__/pages/Billing/fixtures";

const BillingInfo = styled.div`
    p {
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: var(--letter-spacing);
        font-size: var(--body-1-d);
        margin-bottom: 8px;

        @media (max-width: 767px) {
            font-size: var(--body-2-d);
        }
    }
`;

const TotalAmountInfo = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    margin-top: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);

    p {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: var(--letter-spacing);
        font-size: var(--subtitle-1-d);
        margin-bottom: 8px;
        font-weight: 500;

        @media (max-width: 767px) {
            font-size: var(--subtitle-2-d);
        }
    }
`;

const Label = styled.span`
    color: var(--light-200);
`;

const Value = styled.span`
    color: var(--white);
`;

const TaxSkeleton = styled.div`
    height: 25px;
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const PaddleOrderDetails = ({ selectedItem, itemType, pricing }) => {
    const showTax = pricing?.tax && parseFloat(pricing.tax) > 0;
    const showDiscount = pricing?.discount && parseFloat(pricing.discount) > 0;
    let intervalPrefix = "";
    if (itemType === ITEM_TYPE.SUBSCRIPTION) {
        intervalPrefix =
            selectedItem?.interval === billingConstants.INTERVALS.YEARLY
                ? " per year"
                : " per month";
    }

    return pricing ? (
        <div>
            <BillingInfo>
                <p>
                    <Label>{"Credit(s)" + intervalPrefix}</Label>
                    <Value>
                        {abbreviateNumber(selectedItem.features?.credits?.quantity, 99999)}
                    </Value>
                </p>
                {itemType === "subscription" && (
                    <>
                        <p>
                            <Label>Storage</Label>
                            <Value>{selectedItem.features?.storage?.quantity} GB</Value>
                        </p>
                        <p>
                            <Label>Billing</Label>
                            <Value>{capitalizeFirstLetter(selectedItem.interval)}</Value>
                        </p>
                    </>
                )}
                <p>
                    <Label>Item Total</Label>
                    <Value>{formatPrice(pricing.subtotal, pricing.currencyCode)}</Value>
                </p>
                {showDiscount ? (
                    <p>
                        <Label style={{ color: "var(--primary-light)" }}>Discount</Label>
                        <Value style={{ color: "var(--primary-light)" }}>
                            {" "}
                            - {formatPrice(pricing.discount, pricing.currencyCode)}
                        </Value>
                    </p>
                ) : (
                    ""
                )}
                {showTax ? (
                    <p>
                        <Label style={{ color: "var(--light-400)" }}>Tax</Label>
                        <Value>{formatPrice(pricing.tax, pricing.currencyCode)}</Value>
                    </p>
                ) : (
                    <TaxSkeleton />
                )}
            </BillingInfo>
            <TotalAmountInfo>
                <p>
                    <Label>Total</Label>
                    <Value>{formatPrice(pricing.total, pricing.currencyCode)}</Value>
                </p>
            </TotalAmountInfo>
        </div>
    ) : (
        <LoaderContainer>
            <LoaderIcon />
        </LoaderContainer>
    );
};

export default PaddleOrderDetails;
