import apiUrls from "../../src/common/utils/apiUrls";
import ApiService from "./api.service";

const OrgService = {
    createApp(orgId, data) {
        let axiosOptions = { data };
        return ApiService.post(`${apiUrls.createApp}`.replace(":orgId", orgId), axiosOptions);
    },

    getAppsForOrganization(orgId, queryString) {
        let axiosOptions = {};
        return ApiService.get(
            `${apiUrls.getApps}?${queryString}&sortBy=updatedAt&order=desc`.replace(
                ":orgId",
                orgId,
            ),
            axiosOptions,
        );
    },

    selectAppForOAuth(orgId, appId) {
        let axiosOptions = {};
        return ApiService.post(
            `${apiUrls.selectApps}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },

    updateOrg(data, orgId) {
        let axiosOptions = {
            data,
        };
        return ApiService.patch(
            `${apiUrls.updateOrganizationDetailsById}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    updateApp(orgId, appId, data) {
        let axiosOptions = {
            data,
        };
        return ApiService.patch(
            `${apiUrls.updateApp}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },
    getAppDetails(orgId, appId) {
        let axiosOptions = {};
        return ApiService.get(
            `${apiUrls.getAppDetails}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },
    deleteApp(orgId, appId) {
        let axiosOptions = {};
        return ApiService.delete(
            `${apiUrls.deleteApp}`.replace(":orgId", orgId).replace(":appId", appId),
            axiosOptions,
        );
    },
    createTeamMember(orgId, data) {
        let axiosOptions = { data };
        return ApiService.post(
            `${apiUrls.createTeamMember}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    getTeam(orgId, userId, page, pageSize) {
        let axiosOptions = {};
        if (!userId) {
            userId = "";
        }
        if (!page) {
            page = "";
        }
        if (!pageSize) {
            pageSize = "";
        }
        return ApiService.get(
            `${apiUrls.getTeam}`
                .replace(":orgId", orgId)
                .replace(":userId", userId)
                .replace(":page", page)
                .replace(":pageSize", pageSize),
            axiosOptions,
        );
    },
    getTeamMember(orgId, memberId) {
        let axiosOptions = {};
        return ApiService.get(
            `${apiUrls.getTeamMember}`.replace(":orgId", orgId).replace(":memberId", memberId),
            axiosOptions,
        );
    },
    updateTeamMember(orgId, memberId, data) {
        let axiosOptions = { data };
        return ApiService.patch(
            `${apiUrls.updateTeamMember}`.replace(":orgId", orgId).replace(":memberId", memberId),
            axiosOptions,
        );
    },
    searchUsers(orgId, email) {
        let axiosOptions = {};
        return ApiService.get(
            `${apiUrls.searchUsers}`.replace(":orgId", orgId).replace(":email", email),
            axiosOptions,
        );
    },
    deleteTeamMember(orgId, memberId) {
        let axiosOptions = {};
        return ApiService.delete(
            `${apiUrls.deleteTeamMember}`.replace(":orgId", orgId).replace(":memberId", memberId),
            axiosOptions,
        );
    },

    getRoles() {
        return ApiService.get(`${apiUrls.getRoles}`, {});
    },

    getDomains(orgId, searchQuery, page, pageSize) {
        let axiosOptions = {};
        if (!searchQuery) {
            searchQuery = "";
        }
        if (!page) {
            page = "";
        }
        if (!pageSize) {
            pageSize = "";
        }
        return ApiService.get(
            `${apiUrls.getDomains}`
                .replace(":orgId", orgId)
                .replace(":searchQuery", searchQuery)
                .replace(":page", page)
                .replace(":pageSize", pageSize),
            axiosOptions,
        );
    },

    createDomain(orgId, data) {
        let axiosOptions = { data };
        return ApiService.post(`${apiUrls.createDomain}`.replace(":orgId", orgId), axiosOptions);
    },

    getDomainDetails(orgId, domainId) {
        let axiosOptions = {};

        return ApiService.get(
            `${apiUrls.getDomainDetails}`.replace(":orgId", orgId).replace(":domainId", domainId),
            axiosOptions,
        );
    },

    deleteDomain(orgId, domainId) {
        let axiosOptions = {};
        return ApiService.delete(
            `${apiUrls.deleteDomain}`.replace(":orgId", orgId).replace(":domainId", domainId),
            axiosOptions,
        );
    },
};

export default OrgService;
