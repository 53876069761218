import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { setShowNavbar } from "../redux/actions/menuActions";
import LoaderIcon from "./LoaderIcon";
import { setPublicPropAnonymousId, setUtmSource } from "../utils";
import _ from "lodash";
import {
    ANONYMOUS_ID_PARAM_KEY,
    OPENAI_OAUTH_QUERY_OPTIONS,
    AFFILIATE_QUERY_OPTIONS,
} from "../constants/constants";
import useStoreQueryParams from "@common/hooks/useStoreQueryParams";
import useSessionStorageFromQuery from "../hooks/useSessionStorageFromQuery";
import ChatWidgetHandler from "@common/hooks/ChatWidgetHandler";

const ROUTES_WITHOUT_NAV_AND_HEADER = ["choose-org", "setup-org", "profile", "checkout"];

const LoaderContainer = styled.div`
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
`;

const PrivateRoute = ({ component: Component, guard, path }) => {
    useStoreQueryParams(AFFILIATE_QUERY_OPTIONS);
    useStoreQueryParams(OPENAI_OAUTH_QUERY_OPTIONS);
    useSessionStorageFromQuery(["redirectToFreePropertyFlow"]);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoaderVisible, setIsLoaderVisible] = useState(true);
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    useEffect(() => {
        if (search) {
            const _utmSource = query.get("utm_source");
            const _publicPropAnonymousId = query.get(ANONYMOUS_ID_PARAM_KEY);
            setUtmSource(_utmSource);
            setPublicPropAnonymousId(_publicPropAnonymousId);
        }
    }, [search]);

    useEffect(() => {
        if (window.analytics)
            window.analytics?.page(
                _.pick(location, "path", "referrer", "search", "title", "url", "state"),
            );
        if (typeof window !== "undefined" && window.posthog) window.posthog?.capture("$pageview");
    }, [location]);

    const isOrg = (value) => {
        let str = [path, value].join(",");
        return ROUTES_WITHOUT_NAV_AND_HEADER.filter((route) => str.includes(route)).length;
    };

    useEffect(async () => {
        const value = await guard();
        if (isOrg(value) || ["/auth/emailVerification", "/userinfo"].includes(location.pathname)) {
            setShowNavbar(false, dispatch);
        } else {
            setShowNavbar(true, dispatch);
        }
        if (typeof value === "object") {
            history.push(value.location + value.search);
        } else {
            if (value) {
                history.push(value);
            }
        }
        setIsLoaderVisible(false);
    }, []);

    ChatWidgetHandler();

    return isLoaderVisible ? (
        <LoaderContainer>
            <LoaderIcon />
        </LoaderContainer>
    ) : (
        <Component />
    );
};

export default PrivateRoute;
