import { AuthAction } from "../actions/authActions";

const initialState = { isUserLoggedIn: false, user: null, redirectUrl: null };

const authReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    switch (type) {
        case AuthAction.LoginSuccess:
        case AuthAction.LoginFailed:
        case AuthAction.GoogleLoginSuccess:
        case AuthAction.GoogleLoginFailed:
        case AuthAction.RegisterSuccess:
        case AuthAction.RegisterFailed:
        case AuthAction.Logout:
        case AuthAction.CheckUserSession:
        case AuthAction.UpdateDetailsSuccess:
        case AuthAction.UpdateDetailsFailed:
        case AuthAction.UpdateUserMetaSuccess:
        case AuthAction.UpdateUserMetaFailed:
        case AuthAction.SetRedirectUrl:
        case AuthAction.ClearRedirectUrl:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default authReducer;
