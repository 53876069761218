import { GlamarSdkActions } from "../actions/mirrorActions";

const initialState = {
    isBeautySdk: true,
    isStyleSdk: false,
    selectedTabItem: null,
    isBeautySdkLoaded: false,
    isStyleSdkLoaded: false,
    isFaceCaptureScreenState: false,
    isUiSliderState: true,
};

const sdkReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    switch (type) {
        case GlamarSdkActions.ShowBeautySdk:
        case GlamarSdkActions.HideBeautySdk:
            return {
                ...state,
                isBeautySdk: payload,
            };
        case GlamarSdkActions.ShowStyleSdk:
        case GlamarSdkActions.HideStyleSdk:
            return {
                ...state,
                isStyleSdk: payload,
            };
        case GlamarSdkActions.SetSelectedTabItem:
            return {
                ...state,
                selectedTabItem: payload,
            };
        case GlamarSdkActions.SetBeautySdkLoaded:
            return {
                ...state,
                isBeautySdkLoaded: payload,
            };
        case GlamarSdkActions.SetStyleSdkLoaded:
            return {
                ...state,
                isStyleSdkLoaded: payload,
            };

        case GlamarSdkActions.SetFaceCaptureScreenState:
            return {
                ...state,
                isFaceCaptureScreenState: payload,
            };

        case GlamarSdkActions.SetUiSliderState:
            return {
                ...state,
                isUiSliderState: payload,
            };

        default:
            return state;
    }
};

export default sdkReducer;
