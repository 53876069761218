import { ENABLE, DISABLE } from "../actions/toggleActions";

const initialState = {
    isDisabled: false,
};

const toggleReducer = (state = initialState, action = { type: "", payload: {} }) => {
    switch (action.type) {
        case DISABLE:
            return {
                ...state,
                isDisabled: true,
            };
        case ENABLE:
            return {
                ...state,
                isDisabled: false,
            };
        default:
            return state;
    }
};

export default toggleReducer;
