import { ZoneAction } from "../actions/zoneActions";

const initialState = {
    zoneList: {
        zones: [],
        page: {},
    },
    zone: {},
};

const zoneReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    const newState = { ...state };
    let zone;

    switch (type) {
        case ZoneAction.GetZones:
            const prevZones = state.zoneList.zones;
            const nextZones = payload.zoneList.zones;
            const updatedState = { ...state, ...payload };
            updatedState.zoneList.zones = [...prevZones, ...nextZones];
            return updatedState;
        case ZoneAction.ZoneCreateSuccess:
            zone = payload.zone;
            newState.zoneList.zones[zone._id] = zone;
            return {
                ...state,
                ...newState,
            };
        case ZoneAction.ZoneCreateFailed:
            return {
                ...state,
            };
        case ZoneAction.UpdateZone:
            if (payload.isZoneUpdated) {
                zone = payload.zone;
                newState.zoneList.zones[zone._id] = zone;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case ZoneAction.GetZone:
            if (payload.exists) {
                zone = payload.zone;
                newState.zoneList.zones[zone._id] = zone;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case ZoneAction.DeleteZone:
            zone = payload.zone;
            newState.zoneList.zones = newState.zoneList.zones.filter((_) => _._id !== zone);
            return {
                ...state,
                ...newState,
            };
        default:
            return state;
    }
};

export default zoneReducer;
