import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "@redux-devtools/extension";

const store = createStore(
    rootReducer,
    composeWithDevTools({ trace: true })(applyMiddleware(thunk)),
);

export default store;
