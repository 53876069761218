import environment from "@src/environment";
import { getCurrentSession } from "@common/utils/utils";

class FreePropertyGuard {
    initializeFreePropertyGuard() {
        const queryParams = new URLSearchParams();
        const redirectToFreePropertyFlow = sessionStorage.getItem("redirectToFreePropertyFlow");
        const utm_source = sessionStorage.getItem("utm_source");
        if (redirectToFreePropertyFlow) {
            queryParams.append("redirectToFreePropertyFlow", redirectToFreePropertyFlow);
            sessionStorage.removeItem("redirectToFreePropertyFlow");
        }
        if (utm_source) {
            queryParams.append("utm_source", utm_source);
        }
        return queryParams;
    }

    redirectToFreeProperties(utmSource, orgId) {
        let baseUrl = "";
        switch (utmSource) {
            case "erasebg":
                baseUrl = environment.SAKURA_MAIN_DOMAIN;
                break;
            case "upscalemedia":
                baseUrl = environment.SARADA_MAIN_DOMAIN;
                break;
            case "watermarkremover":
                baseUrl = environment.SUIGETSU_MAIN_DOMAIN;
                break;
            case "glamar":
                baseUrl = environment.KIBA_MAIN_DOMAIN;
                break;
            case "shrinkmedia":
                baseUrl = environment.SAI_MAIN_DOMAIN;
                break;
            case "convertfiles":
                baseUrl = environment.SATETSU_MAIN_DOMAIN;
                break;
            default:
                baseUrl = environment.HINATA_MAIN_DOMAIN;
        }
        let redirectUrl = new URL(baseUrl);
        if (orgId) {
            redirectUrl.searchParams.append("orgId", orgId);
        }
        return redirectUrl.toString();
    }

    async guard(orgId) {
        const queryParams = this.initializeFreePropertyGuard();
        const redirectToFreePropertyFlow = queryParams.get("redirectToFreePropertyFlow");
        const redirectToPixelbinFlow = queryParams.get("redirectToPixelbinPropertyFlow");
        const utmSource = queryParams.get("utm_source");

        if (utmSource) {
            if (redirectToFreePropertyFlow === "true") {
                this.handleRedirectToFreePropertyFlow(utmSource, orgId);
                return "done";
            } else if (redirectToPixelbinFlow === "true") {
                this.handleRedirectToPixelbinFlow(utmSource, orgId);
                return "done";
            } else if (this.redirectUrl) {
                this.handleRedirectToUrl(this.redirectUrl);
                return "done";
            }
        }
        return "";
    }

    async handleRedirectToFreePropertyFlow(utmSource, orgId) {
        return getCurrentSession()
            .then((res) => {
                const url = new URL(this.redirectToFreeProperties(utmSource, orgId));
                url.searchParams.append("token", res.bgSession);
                window.location.href = url.toString();
            })
            .catch((err) => {
                console.log("An error occurred while fetching the session:", err);
                window.location.href = this.redirectToFreeProperties(utmSource, orgId);
            });
    }

    async handleRedirectToPixelbinFlow(utmSource, orgId) {
        return getCurrentSession()
            .then((res) => {
                const baseUrl = this.redirectToFreeProperties(utmSource, orgId);
                const url = new URL(`${baseUrl}/redirectToPixelbin`);
                url.searchParams.append("token", res.bgSession);
                url.searchParams.append("redirectURI", environment.HINATA_MAIN_DOMAIN);
                window.location.href = url.toString();
            })
            .catch((err) => {
                console.log("An error occurred while fetching the session:", err);
            });
    }

    async handleRedirectToUrl(redirectUrl) {
        return getCurrentSession()
            .then((res) => {
                const url = new URL(redirectUrl);
                url.searchParams.append("token", res.bgSession);
                window.open(url.toString(), "_self");
            })
            .catch((err) => {
                console.log("An error occurred while fetching the session:", err);
            });
    }
}

export default FreePropertyGuard;
