import apiUrls from "../common/utils/apiUrls";
import ApiService from "./api.service";

const AuthService = {
    register(formData, stateSearchParam) {
        let axiosOptions = Object.assign({}, { data: formData });
        return ApiService.post(`${apiUrls.registerUser}?${stateSearchParam}`, axiosOptions);
    },

    login(formData) {
        let axiosOptions = Object.assign({}, { data: formData });
        return ApiService.post(apiUrls.loginUser, axiosOptions);
    },

    setFreePropertiesCookie(tokenID, domainToSet) {
        let axiosOptions = {
            params: {
                tokenID,
            },
        };
        return ApiService.post(domainToSet, axiosOptions);
    },

    forgotPassword(formData) {
        let axiosOptions = Object.assign({}, { data: formData });
        return ApiService.post(apiUrls.forgotPassword, axiosOptions);
    },

    resetPassword(formData) {
        let axiosOptions = Object.assign({}, { data: formData });
        return ApiService.post(apiUrls.resetPassword, axiosOptions);
    },

    verifyEmail(code, sessionState) {
        let axiosOptions = Object.assign({}, { data: { code } });
        return ApiService.post(
            `${apiUrls.verifyEmail}?state=${JSON.stringify(sessionState)}`,
            axiosOptions,
        );
    },

    loginViaGoogle(data) {
        let axiosOptions = Object.assign({}, { data });
        return ApiService.post(apiUrls.googleLogin, axiosOptions);
    },

    logout() {
        let axiosOptions = Object.assign({}, {});
        return ApiService.post(apiUrls.logoutUser, axiosOptions);
    },

    updateProfile(updateOpts) {
        const axiosOptions = {
            data: updateOpts,
        };
        return ApiService.patch(apiUrls.profileDetails, axiosOptions);
    },

    updateEmail(updateOpts) {
        const axiosOptions = {
            data: updateOpts,
        };
        return ApiService.post(apiUrls.updateEmailInit, axiosOptions);
    },

    verifyEmailUpdate(code) {
        return ApiService.post(apiUrls.updateEmailComplete, {
            data: { code },
        });
    },

    updatePassword(newPassword) {
        return ApiService.post(apiUrls.updatePassword, { data: { password: newPassword } });
    },

    updateMeta(updateOpts) {
        return ApiService.patch(apiUrls.updateMeta, { data: updateOpts });
    },
};

export default AuthService;
