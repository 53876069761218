import React from "react";
import styled from "styled-components";
import LoaderIcon from "./LoaderIcon";

const LoaderContainer = styled.div`
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
`;

const Loader = () => {
    return (
        <LoaderContainer>
            <LoaderIcon />
        </LoaderContainer>
    );
};

export default Loader;
