import { cloneDeep } from "lodash";

export const ifRenderingConditionSatisifes = (config, formData) => {
    for (let i = 0; i < config?.condition?.length; i++) {
        let valueToCheck =
            formData[config?.condition[i]?.key]?.value || formData[config?.condition[i]?.key];
        switch (config?.condition[i].type) {
            case "=":
                if (valueToCheck !== config?.condition[i]?.match) {
                    return false;
                }
                break;
            case "exists":
                if (!config?.condition[i]?.match?.includes(valueToCheck)) {
                    return false;
                }
                break;
        }
    }
    return true;
};
const addIdentifierToFormData = (formData, eachConfigData) => {
    let FormData = formData;
    if (ifRenderingConditionSatisifes(eachConfigData, FormData)) {
        if (eachConfigData?.parent) {
            FormData = {
                ...FormData,
                [`${eachConfigData?.parent}`]: {
                    ...FormData[`${eachConfigData?.parent}`],
                    [`${eachConfigData?.identifier}`]: eachConfigData?.value,
                },
            };
        } else if (eachConfigData?.hasChild) {
            FormData = {
                ...FormData,
                [`${eachConfigData?.identifier}`]: {
                    ...FormData[`${eachConfigData?.identifier}`],
                    value: eachConfigData?.value,
                },
            };
        } else {
            FormData = { ...FormData, [`${eachConfigData?.identifier}`]: eachConfigData?.value };
        }
    }
    return FormData;
};
export const buildDefaultFormData = (state) => {
    const FormConfig = state?.formConfig;
    let FormData = {};
    Object.keys(FormConfig)?.forEach((formConfigKey) => {
        let eachRowData = FormConfig[formConfigKey];
        if (Array.isArray(eachRowData)) {
            eachRowData?.forEach((eachElementInaRow) => {
                if (Array.isArray(eachElementInaRow)) {
                    eachElementInaRow?.forEach((config) => {
                        FormData = addIdentifierToFormData(FormData, config);
                    });
                } else {
                    if (eachElementInaRow?.condition) {
                        FormData = addIdentifierToFormData(FormData, eachElementInaRow);
                    } else {
                        FormData[eachElementInaRow?.identifier] = eachElementInaRow?.value;
                    }
                }
            });
        } else {
            if (eachRowData?.condition) {
                FormData = addIdentifierToFormData(FormData, eachRowData);
            } else {
                FormData[eachRowData?.identifier] = eachRowData?.value;
            }
        }
    });
    return FormData;
};

const isChangeInValueRequired = (changedFieldConfig, elementConfig) => {
    if (changedFieldConfig?.label === elementConfig?.label) {
        return false;
    }
    return true;
};

const addValueToFormdata = (element, formData, fieldConfig) => {
    if (element?.isDependent && isChangeInValueRequired(fieldConfig, element)) {
        let value;
        if (element?.type === "2dImageInput") {
            value = cloneDeep(element?.enum?.[formData?.[element?.dependentKey]]);
        } else {
            value = element?.enum?.[formData?.[element?.dependentKey]]?.[0]?.value;
        }

        if (element?.parent) {
            let parentKey = element?.parent;
            let keyToUpdate = element?.identifier;
            if (typeof formData[parentKey][keyToUpdate] === "object") {
                formData[parentKey][keyToUpdate].value = value;
            } else {
                formData[parentKey][keyToUpdate] = value;
            }
        } else {
            let keyToUpdate = element?.identifier;
            if (
                (typeof formData[keyToUpdate] === "object" || element?.hasChild) &&
                !Array.isArray(formData[keyToUpdate])
            ) {
                if (!formData[keyToUpdate]) {
                    formData[keyToUpdate] = {};
                }
                formData[keyToUpdate].value = value;
            } else {
                formData[keyToUpdate] = value;
            }
        }
    } else {
        if (element?.parent) {
            if (formData[element?.parent][element?.identifier]) {
                return formData;
            } else {
                formData[element.parent][element.identifier] = element?.value;
            }
        } else {
            if (formData[element?.identifier] || formData[element?.identifier]?.value) {
                return formData;
            } else {
                if (element?.hasChild) {
                    if (!formData[element?.identifier]) {
                        formData[element?.identifier] = {};
                    }
                    formData[element?.identifier].value = element?.value;
                } else {
                    formData[element?.identifier] = element?.value;
                }
            }
        }
    }
    return formData;
};

const handleCurrentRowCascade = (fieldConfig, rowConfig, formData) => {
    let indexToStart = fieldConfig?.index + 1;
    let updatedFormdata;
    for (let i = indexToStart; i < rowConfig?.length; i++) {
        let eachElementInaRow = rowConfig[i];
        if (Array.isArray(eachElementInaRow)) {
            for (let j = 0; j < eachElementInaRow?.length; j++) {
                let config = eachElementInaRow[j];
                if (config?.condition) {
                    if (ifRenderingConditionSatisifes(config, formData)) {
                        updatedFormdata = addValueToFormdata(config, formData, fieldConfig);
                    } else {
                        formData[config?.identifier] && delete formData[config?.identifier];
                        config?.parent && delete formData[config?.parent][config?.identifier];
                    }
                } else {
                    updatedFormdata = addValueToFormdata(config, formData, fieldConfig);
                }
            }
        } else {
            if (eachElementInaRow?.condition) {
                if (ifRenderingConditionSatisifes(eachElementInaRow, formData)) {
                    updatedFormdata = addValueToFormdata(eachElementInaRow, formData, fieldConfig);
                } else {
                    delete formData[eachElementInaRow?.identifier];
                }
            } else {
                updatedFormdata = addValueToFormdata(eachElementInaRow, formData, fieldConfig);
            }
        }
    }
    updatedFormdata = formData;
    return updatedFormdata;
};

const handleNextRowsCascade = (fieldConfig, rowConfig, formData) => {
    let formdata;
    if (Array.isArray(rowConfig)) {
        rowConfig?.forEach((eachElementInaRow) => {
            if (Array.isArray(eachElementInaRow)) {
                eachElementInaRow?.forEach((config) => {
                    if (config?.condition) {
                        if (ifRenderingConditionSatisifes(config, formData)) {
                            formdata = addValueToFormdata(config, formData, fieldConfig);
                        } else {
                            formData[config?.identifier] && delete formData[config?.identifier];
                            config?.parent &&
                                formData?.[config?.parent]?.[config?.identifier] &&
                                delete formData[config?.parent][config?.identifier];
                        }
                    } else {
                        formdata = addValueToFormdata(config, formData, fieldConfig);
                    }
                });
            } else {
                if (eachElementInaRow?.condition) {
                    if (ifRenderingConditionSatisifes(eachElementInaRow, formData)) {
                        formdata = addValueToFormdata(eachElementInaRow, formData, fieldConfig);
                    } else {
                        delete formData[eachElementInaRow?.identifier];
                    }
                } else {
                    formdata = addValueToFormdata(eachElementInaRow, formData, fieldConfig);
                }
            }
        });
    } else {
        if (rowConfig?.condition) {
            if (ifRenderingConditionSatisifes(rowConfig, formData)) {
                formdata = addValueToFormdata(rowConfig, formData, fieldConfig);
            } else {
                delete formData[rowConfig?.identifier];
            }
        } else {
            formdata = addValueToFormdata(rowConfig, formData, fieldConfig);
        }
    }
    formdata = formData;
    return formdata;
};

const handleCascade = (fieldConfig, formConfig, formData) => {
    let formdata;
    for (let i = fieldConfig?.rowIndex; i < Object.keys(formConfig)?.length; i++) {
        let rowConfig = formConfig[i];
        if (i === fieldConfig?.rowIndex) {
            formdata = handleCurrentRowCascade(fieldConfig, rowConfig, formData);
        } else {
            formdata = handleNextRowsCascade(fieldConfig, rowConfig, formData);
        }
    }
    return formdata;
};
export const updateFormData = (state, payload) => {
    let formData = state?.formData;
    let fieldConfig = payload?.fieldConfig;
    let keyToUpdate = fieldConfig?.identifier;
    if (fieldConfig?.parent) {
        let parentKey = fieldConfig?.parent;
        if (typeof formData[parentKey][keyToUpdate] === "object") {
            formData[parentKey][keyToUpdate].value = payload.value;
        } else {
            formData[parentKey][keyToUpdate] = payload.value;
        }
    } else {
        if (typeof formData[keyToUpdate] === "object" && fieldConfig?.hasChild) {
            formData[keyToUpdate].value = payload.value;
        } else {
            formData[keyToUpdate] = payload.value;
        }
    }
    if (fieldConfig?.updateFormCascadeEffect) {
        formData = handleCascade(fieldConfig, state?.formConfig, formData);
    } else {
        return { ...formData };
    }
    return { ...formData };
};

export const createFormDataFromApiResponse = (apiRes) => {
    const formData = {};
    formData["productName"] = apiRes?.productName;
    formData["productImage"] = apiRes?.productImage || "";
    formData["barcode"] = apiRes?.itemCode || "";
    formData["brand"] = apiRes?.vendor || "";
    formData["category"] = apiRes?.category;
    formData["subCategory"] = apiRes?.subCategory;
    formData["isActive"] = apiRes?.isActive;
    formData["intensity"] = apiRes?.meta?.colorIntensity;
    if (apiRes?.styleVariant) {
        formData["style"] = apiRes?.styleVariant;
        formData["styleReferenceImage"] = apiRes?.styleIcon;
        let patterns = apiRes?.attributes;
        for (let i = 0; i < patterns?.length; i++) {
            formData[`pattern${i}`] = {
                value: patterns[i]?.name,
            };
            let colors = patterns[i]?.colors;
            for (let j = 0; j < colors?.length; j++) {
                formData[`pattern${i}`][`color${j}`] = colors[j];
            }
        }
    } else {
        if (apiRes?.subCategory === "2d") {
            formData["2dImages"] = apiRes?.attributes[0]?.effectAssets?.map((data) => {
                return {
                    file: data,
                };
            });
        } else {
            formData["3dFile"] = [
                {
                    file: apiRes?.attributes[0]?.effectAssets?.[0],
                },
            ];
        }
    }
    return formData;
};
