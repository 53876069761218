import { createGlobalStyle, css } from "styled-components";
import CloseIcon from "common/assets/svgs/Cross.svg";
import SuccessIcon from "common/assets/svgs/Tick Alt.svg";
import ErrorIcon from "common/assets/svgs/Close Alt.svg";
import InfoIcon from "common/assets/svgs/Info Alt.svg";
import WarnIcon from "common/assets/svgs/Warn Alt.svg";

export const Breakpoints = {
    mobileXS: 480,
    mobile: 768,
    tablet: 1024,
    laptop: 1200,
    desktop: 1440,
};

export const SideNavBarWidth = `250px`;

const ToastCss = css`
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.3);
    @media (min-width: ${Breakpoints.mobile}px) {
        border-radius: 8px !important;
    }
`;

const ToastIconCss = css`
    position: absolute;
    left: 12px;
    top: 6px;
    position: absolute;
    left: 14px;
    top: 0;
    height: calc(100% + 3px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GlobalStyle = createGlobalStyle`

    :root {
        --brand-gradient: linear-gradient(95.78deg, #902EF2 0%, #FF62B7 100%);
        --brand-gradient-reverse: linear-gradient(95.78deg, #FF62B7 0%, #902EF2 100%);
        --profile-gradient: linear-gradient(95.78deg, #902EF2 0%, #FF62B7 100%);
        --button-gradient: var(--brand-gradient);
        --border-gradient: var(--brand-gradient);

        --primary-erasebg: #d92a7e;
        --brand-gradient-erasebg: linear-gradient(120.55deg, #FF4040 18.56%, #A20BD8 98.01%);
        --brand-gradient-erasebg-reverse: linear-gradient(120.55deg, #A20BD8 18.56%, #FF4040 98.01%);
        --primary-upscalemedia: #17C969;
        --brand-gradient-upscalemedia: linear-gradient(107.65deg,#17C969 17.53%,#005DBB 94.64%);
        --brand-gradient-upscalemedia-reverse: linear-gradient(107.65deg,#005DBB 17.53%,#17C969 94.64%);
        --primary-watermarkremover: #F9D423;
        --brand-gradient-watermarkremover: linear-gradient(132.2deg, #F9D423 7.64%, #FF4E50 97.11%);
        --brand-gradient-watermarkremover-reverse: linear-gradient(132.2deg, #FF4E50 7.64%, #F9D423 97.11%);
        --primary-shrink: #AD00FF;
        --brand-gradient-shrink:linear-gradient(90.36deg, #AD00FF 0.31%, #330371 104.9%);
        --brand-gradient-shrink-reverse: linear-gradient(120.55deg, #330371 18.56%, #AD00FF 98.01%);
        --primary-glamar: #DA0E64;
        --brand-gradient-glamar: linear-gradient(96deg, #DA0E64 0.31%, #E6606F 97.23%);
        --brand-gradient-glamar-reverse: linear-gradient(96deg, #E6606F 0.31%, #DA0E64 97.23%);
        --background-highlight-gradient: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.04),
                    rgba(255, 255, 255, 0.04)
                ),
                #101112;
        --primary-convertfiles: #01A5FF;
        --brand-gradient-convertfiles: linear-gradient(92.99deg, #0148BA 1.35%, #01A5FF 104.47%);
        --brand-gradient-convertfiles-reverse: linear-gradient(92.99deg, #01A5FF 1.35%, #0148BA 104.47%);


        --primary-dark: #6212B2;
        --primary: #902EF2;
        --primary-light: #B264FF;
        --primary-hover: #8110F1;
        --primary-main: #902EF2;
        --glam-ar-primary: #DA0E64;
        --primary-dark-bg: #020414;

        --secondary-dark: #E83094;
        --secondary: #FF62B7;
        --secondary-light: #FF99D0;
        --secondary-bg: #A50CD4;

        --tertiary : rgba(255, 255, 255, 0);
        --icon-hover-bg: #323648;
        --white: #FFFFFF;
        --white-80: rgba(255,255,255,0.8);
        --light-100: #ECECF9;
        --light-200: #C6CFE2;
        --light-300: #AAABC5;
        --light-400: #8A8AA8;
        --black: #0B0E10;
        --dark-900: #171921;
        --dark-800: #1D202A;
        --dark-801: #0A0D10;
        --dark-700: #242B38;
        --dark-600: #323648;
        --success-dark: #1B934B;
        --success: #1FBE5F;
        --success-light: #24E070;
        --danger-dark: #D42B4A;
        --danger: #E83F5D;
        --danger-light: #FD4969;
        --danger-hover: #FE2F54;
        --info: #87cefa;
        --partial-success: #FFA500;
        --warning: #ffff99;
        --error: #ff3333;
        --divider: #dbdeff29;
        --warning-light: #DECC31;

        --purple-1: #5c2d96;

        --border-dark: rgba(203, 205, 254, 0.16);
        --border-light: rgba(203, 205, 254, 0.22);
        --border: #323232;
        --border-light-100: #302F2F;
        --light-bg-1: rgba(196, 211, 249, 0.07);
        --light-bg-1-hover: rgba(255, 255, 255, 0.11);
        --border-radius-20: 20px;
        --border-radius-16: 16px;
        --border-radius-12: 12px;
        --border-radius-10: 10px;
        --border-radius-8: 8px;
        --border-radius-6: 6px;
        --border-radius-4: 4px;
        --letter-spacing-0: 0%;
        --letter-spacing-1: 1%;
        --letter-spacing-15: 1.5%;
        --letter-spacing-1N: -1%;
        --letter-spacing-05N: -0.5%;
        --letter-spacing-15N: -1.5%;
        --h1-letter-spacing-d: 0.5px;
        --h1-letter-spacing-m: 0.2px;
        --h2-letter-spacing-d: 0.5px;
        --h2-letter-spacing-m: 0.2px;
        --h3-letter-spacing-d: 0.5px;
        --h3-letter-spacing-m: 0.2px;
        --heading-font : Inter, sans-serif;
        --body-font : Inter, sans-serif;
        /* typography */
        --h1-d: 56px;
        --h1-m: 40px;
        --h2-d: 40px;
        --h2-m: 32px;
        --h3-d: 32px;
        --h3-m: 24px;
        --subtitle-1-d: 24px;
        --subtitle-1-m: 20px;
        --subtitle-2-d: 20px;
        --subtitle-2-m: 18px;
        --subtitle-3-d: 18px;
        --subtitle-3-m: 16px;
        --subtitle-4-d: 16px;
        --subtitle-4-m: 14px;
        --lead-body-d: 18px;
        --lead-body-m: 16px;
        --body-lead-d: 18px;
        --body-lead-m: 18px;
        --body-1-d: 16px;
        --body-1-m: 15px;
        --body-2-d: 14px;
        --body-2-m: 13px;
        --body-3-d: 12px;
        --body-3-m: 11px;
        --body-4-d: 12px;
        --body-4-m: 10px;
        --caption-1-d: 14px;
        --caption-1-m: 12px;
        /* Button font size */
        --button: 16px;
        --button-small: 14px;
        --font-weight-600: 600;
        --font-weight-400: 400;
        --bold: 700;
        --semi-bold: 600;
        --regular: 400;
        --line-height-160: 160%;
        --line-height-140: 140%;
        --line-height-120: 120%;
        --line-height-62: 62px;
        --line-height-58: 58px;
        --line-height-56: 56px;
        --line-height-49: 49px;
        --line-height-40: 40px;
        --line-height-36: 36px;
        --line-height-34: 34px;
        --line-height-32: 32px;
        --line-height-29: 29px;
        --line-height-28: 28px;
        --line-height-25: 25px;
        --line-height-24: 24px;
        --line-height-22: 22px;
        --line-height-21: 21px;
        --line-height-19: 19px;
        --line-height-16: 16px;
        --bg-color: #101112;
        --secondary-bg-color: rgba(255, 255, 255, 0.02);
        --blue-bg: rgba(94, 177, 255, 1);
        --bg-color-80: rgba(16,17,18,0.8);
        --error: #FF5858;
        --secondary-border-color: #50ABFF;
        --button-border: #d92a7e;
        --secondary-button-border: #323232;
        --banner-title-max-width: 1039px;
        --banner-description-max-width: 795px;
        --section-description-max-width: 795px;
        --content-max-width: 498px;
        --font-size-base: 14px ; // Assumes the browser, typically 16px
        --font-size-sm: 12px;
        --font-size-lg: 16px;
        --bg-error: #E53F64;
        --error: #E53F64;
        --dropdown-bg: #28292A;
        --dark: #101112;
        --light: var(--white-100);
        --disabled: rgba(97,97,97);
        --upload-bg-error: #be6464;
        --banner-nav-height-1: 99px;
        --banner-nav-height-2: 107px;
        --surface-3: #FFFFFF14;
        --brand-gradient: linear-gradient(120.55deg,#FF4040 18.56%,#A20BD8 98.01%);
        --brand-gradient-reverse: linear-gradient(120.55deg,#A20BD8 18.56%,#FF4040 98.01%);
        --glamar-product-list-color-size:32px;
        --glamar-grey: #323648;
        --glamar-hover-button: #E6606F;
        --rsbs-bg: rgb(36, 43, 56);
        --rsbs-overlay-rounded: 12px;
        --glamar-outlined-hover: #302F2F;

        --doc-height: 100%; // later updated by javascript to use window.innerHeight, ScriptHooks.js
    }
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: var(--body-font);
        letter-spacing: var(--letter-spacing);
    }
    html {
        height: 100%;
    }
    body {
        height: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: var(--bg-color);
        font-family: var(--body-font);
        color: var(--white);
    }
    a {
        text-decoration: none;
    }
    button {
        outline: 0;
        border: none;
        cursor: pointer;
    }
    input {
        border: 1px solid transparent;
        outline: none;
        letter-spacing: var(--letter-spacing);
    }
    input[type="checkbox"]{
        width: 20px;
        height: 20px;
        accent-color: var(--primary-light);
    }
    input[type="radio"]{
        width: 20px;
        height: 20px;
        accent-color: var(--primary-light);
    }
    input[type="range"] {
        -webkit-appearance: none;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-top: -5px;
        background: var(--primary-light);
        cursor: pointer;
        transition: background .3s ease-in-out;
    }

    input[type=range]::-webkit-slider-runnable-track  {
        -webkit-appearance: none;
        height: 4px;
        border-radius: 2px;
        border:1px solid var(--primary-light);
        background: transparent;
    }
    /* firefox */
        input[type=range]::-moz-range-thumb {
        border: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: var(--primary-light);
    }
    input[type=range]::-moz-range-track {
        height: 2px;
        background: transparent;
        border-radius: 2px;
        border:1px solid var(--primary-light);
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }
    /* Scrollbar Styling */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #6d6d6d;
    }


    *,
    *:before,
    *:after {
        /* outline: 1px solid dodgerblue; // for debugging layout */
    }
    /* Remove Remove Arrows/Spinners */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    input[type=checkbox]:indeterminate {
        content: "";
        display: block;
        color: white;
        width: 20px;
        height: 20px;
        background-color:var(--dark-600);
        position: relative;
    }
    input[type=checkbox]:indeterminate::before {
        content: "";
        display: block;
        color: white;
        width: 20px;
        height: 20px;
        background-color:var(--dark-600);
        border: 1px solid var(--border-light);
        border-radius: 4px;
    }
    input[type=checkbox]:indeterminate::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border: solid white;
        border-width: 2px 0 0 0;
        position: absolute;
        top: 10px;
        left: 6px;
    }
    /* ************************* */
    /* ************************* */
    /* Custom Toast design start */
    /* ************************* */
    /* ************************* */

    .Toastify__toast--success::after, .Toastify__toast--error::after, .Toastify__toast--info::after, .Toastify__toast--warning::after {
        content : url(${CloseIcon});// Your svg Path
        position: absolute;
        right: 14px;
        top: 0;
        height: calc(100% + 3px);
        color: var(--light-100);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Toastify__toast--success {
        ${ToastCss}
        background: #1A5637 !important;
    }
    .Toastify__toast--success::before {
        ${ToastIconCss}
        content: url(${SuccessIcon});
    }
    /* Info Toast Styles */
    .Toastify__toast--info {
        ${ToastCss}
        background: var(--primary-light) !important;
    }
    .Toastify__toast--info::before {
        ${ToastIconCss}
        content: url(${InfoIcon});
    }
    /* Error Toast */
    .Toastify__toast--error {
        ${ToastCss}
        background: #7C3034 !important;
    }
    .Toastify__toast--error::before {
        ${ToastIconCss}
        content: url(${ErrorIcon});
    }
    /* Warn Toast */
    .Toastify__toast--warning {
        ${ToastCss}
        background: linear-gradient(0deg, rgba(223, 227, 31, 0.4), rgba(223, 227, 31, 0.4)), #171921 !important;
    }
    .Toastify__toast--warning::before {
        ${ToastIconCss}
        content: url(${WarnIcon});
    }
    .Toastify__toast-body {
        color: var(--white);
        font-size: var(--body-2-d);
        padding: 10px 40px;
        font-weight: var(--semi-bold);
    }
    .Toastify__toast > button>  svg {
        display: none;
    }

    /* ************************ */
    /* ************************ */
    /* Custom Toast design ends */
    /* ************************ */
    /* ************************ */

    .pulse {
        animation: pulse-animation 2s infinite;
    }

    @keyframes pulse-animation {
        0% {
            box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.4);
        }
        100% {
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }
    }

    .check {
        stroke-dasharray: 1000;
        stroke-dashoffset: -100;
        animation: dash-check 0.9s ease-in-out forwards;
    }

    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }
    .custom_fc_frame {
        z-index: 998 !important;
        bottom: 42px !important;
    }
    #fc_frame {
        @media (max-width: ${Breakpoints.mobile}px) {
            transform: scale(0.7);
        }
    }
    #fc_frame.h-open-container{
        @media (max-width: ${Breakpoints.mobile}px) {
            transform: none;
        }
    }
    .gradient-text-erase {
        background: var(--brand-gradient-erase);
     -webkit-background-clip: text;
      color: transparent;
        -webkit-text-fill-color: transparent;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
    }

    .gradient-text-upscale {
        background: var(--brand-gradient-upscale);
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
    }
    .gradient-text-watermark {
        background: var(--brand-gradient-watermark);
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
    }
    .gradient-text-shrink {
        background: var(--brand-gradient-shrink);
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
    }
    .gradient-text-pixelbin {
        background: var(--brand-gradient);
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
    }
    .gradient-text-convertfiles {
        background: var(--brand-gradient-convertfiles);
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
    }

    .gradient-switch {
        background: var(--brand-gradient);
        border: none;
        color: white;
    }
    div[data-rsbs-header] {
        ::before {
            width: 64px;
            background: rgba(203, 205, 254, 0.16);
        }
    }
    #cancel-sheet {
        div[data-rsbs-overlay] {
            background: var(--dark-800);
        }
        div[data-rsbs-header] {
            ::before {
                display: none;
            }
        }
        div[data-rsbs-scroll] {
            overflow: hidden;
        }
    }
`;
