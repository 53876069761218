import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../GlobalStyle";

const StyledCard = styled.div`
    color: var(--white-90);
    margin: 30px 40px;
    width: 100% !important;
    max-width: 420px !important;
    overflow: hidden;
    @media (max-width: ${Breakpoints.mobile}px) {
        margin: 24px 16px;
    }
`;

export default function AuthCard({ children, ...restProps }) {
    return (
        <StyledCard data-testid="card" {...restProps}>
            {children}
        </StyledCard>
    );
}
