import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { PlaygroundAction } from "../actions/playgroundActions";

const initialState = {
    config: null,
    originalUrl: null,
    originalFormat: null,
    transformedUrl: null,
    previewUrl: null,
    transformationList: [],
    selectedTransformIdx: null,
    appliedAndPreviewedTransformationList: [],
    presetData: {},
    disableAddTransformation: false,
    fromUrl: "",
    defaultTransformation: {},
    editDefaultTransformation: {},
    isPresetsVisible: false,
    isTransformationVisible: false,
    playgroundTab: "transformations",
    originalStatus: {
        state: "loading",
        error: null,
    },
    transformedStatus: {
        state: "loading",
        error: null,
    },
    jsonStatus: {
        state: "idle",
        response: {},
        error: null,
    },
    originalDetails: {},
    previewDetails: {},
};

const getTotalNumberofTransformation = (transformationList) => {
    let noOfTransformation = 0;
    for (let operation of transformationList) {
        if (operation?.isPreset) noOfTransformation += operation.transformation.split("~").length;
        else ++noOfTransformation;
    }
    return noOfTransformation;
};

const playgroundReducer = (state = initialState, action = { type: "", payload: {} }) => {
    let newState = {};
    const { type, payload } = action;
    let preTransformationList, noOfTransformation;
    switch (type) {
        case PlaygroundAction.UpdateTransformationList:
            newState = {
                ...state,
                ...payload,
            };
            newState.disableAddTransformation =
                getTotalNumberofTransformation(newState.transformationList) < 15 ? false : true;

            newState.transformationList = newState.transformationList.map((transformation) => {
                if (transformation && !transformation.id) transformation.id = uuid(); // to unique identify a transformation in list for later update
                return transformation;
            });

            return newState;

        case PlaygroundAction.UpdateTransformationValues:
            let transformationList = state.transformationList;
            let updatedTransformationList = [];

            updatedTransformationList = transformationList.map((eachTransformation) =>
                eachTransformation.id === payload.id ? payload : eachTransformation,
            );

            return {
                ...state,
                transformationList: [...updatedTransformationList],
            };

        case PlaygroundAction.UpdateDefaultTransformationValues:
            let updatedDefaultTransform = {};
            let defaultTransformation = state.defaultTransformation;
            let { defaultOpIdentifier, defaultValue, defaultResetType } = payload;

            if (defaultResetType === "RESET_ALL") {
                let tempValues = defaultTransformation.values;
                if (
                    (payload.currentResetKey !== undefined &&
                        payload.operationMethod !== undefined &&
                        state.config.plugins[payload.currentResetKey]?.operations !== undefined) ||
                    state.config.plugins[payload.currentResetKey]?.operations?.length > 0
                ) {
                    state.config.plugins[payload.currentResetKey]?.operations
                        ?.filter((operation) => operation.method === payload.operationMethod)
                        .forEach((operation) => {
                            operation.params.forEach((parameter) => {
                                tempValues[parameter.identifier] = parameter.default;
                            });
                        });
                }

                updatedDefaultTransform = {
                    plugin: defaultTransformation.plugin,
                    operation: defaultTransformation.operation,
                    values: { ...tempValues },
                };
            } else {
                let tempValues = defaultTransformation.values;
                tempValues[defaultOpIdentifier] = defaultValue;

                updatedDefaultTransform = {
                    plugin: defaultTransformation.plugin,
                    operation: defaultTransformation.operation,
                    values: { ...tempValues },
                };
            }

            return {
                ...state,
                defaultTransformation: { ...updatedDefaultTransform },
            };

        case PlaygroundAction.UpdateEditDefaultTransformationValues:
            let updatedEditDefaultTransform = {};
            let editDefaultTransformation = { ...state.editDefaultTransformation };
            let {
                opIdentifier: editDefaultOpIdentifier,
                value: editDefaultValue,
                resetType: editDefaultResetType,
            } = payload;
            if (editDefaultResetType === "RESET_ALL") {
                let tempValues = {};
                tempValues = { ...editDefaultTransformation.values };

                if (
                    state.config &&
                    state.config.plugins &&
                    payload.currentResetKey &&
                    payload.operationMethod
                ) {
                    const pluginOperations =
                        state.config.plugins[payload.currentResetKey]?.operations || [];

                    pluginOperations
                        .filter((operation) => operation.method === payload.operationMethod)
                        .forEach((operation) => {
                            operation.params.forEach((parameter) => {
                                tempValues[parameter.identifier] = parameter.default;
                            });
                        });
                }

                updatedEditDefaultTransform = {
                    plugin: editDefaultTransformation.plugin,
                    operation: editDefaultTransformation.operation,
                    values: { ...tempValues },
                    id: editDefaultTransformation.id,
                };
            } else {
                let tempValues = {};
                tempValues = { ...editDefaultTransformation.values };
                tempValues[editDefaultOpIdentifier] = editDefaultValue;

                updatedEditDefaultTransform = {
                    plugin: editDefaultTransformation.plugin,
                    operation: editDefaultTransformation.operation,
                    values: { ...tempValues },
                    id: editDefaultTransformation.id,
                };
            }

            return {
                ...state,
                editDefaultTransformation: { ...updatedEditDefaultTransform },
            };

        case PlaygroundAction.AddTransformation:
            let updatedTransformation = {
                ...payload.transformation,
                id: uuid(), // to unique identify a transformation in list for later update
            };

            preTransformationList = state.transformationList.concat(updatedTransformation);

            noOfTransformation = getTotalNumberofTransformation(preTransformationList);
            if (noOfTransformation > 15) {
                toast.error("Cannot add more than 15 transformations.");
                return {
                    ...state,
                };
            } else if (noOfTransformation == 15) {
                state.disableAddTransformation = true;
            }

            return {
                ...state,
                transformationList: preTransformationList,
                selectedTransformIdx:
                    payload.transformation.operation.params.length != 0
                        ? state.transformationList.length
                        : null,
            };

        case PlaygroundAction.EditDefaultTransformation:
            return {
                ...state,
                editDefaultTransformation: payload.transformation,
            };

        case PlaygroundAction.AddDefaultTransformation:
            let defaultValues = {};
            payload.transformation.operation.params.forEach(
                (p) => (defaultValues[p.identifier] = p.default),
            );
            let updatedDefaultTransformation = {
                ...payload.transformation,
                values: { ...defaultValues },
            };

            return {
                ...state,
                defaultTransformation: updatedDefaultTransformation,
            };

        case PlaygroundAction.AddPreset:
            preTransformationList = state.transformationList.concat(payload.transformation);
            noOfTransformation = getTotalNumberofTransformation(preTransformationList);
            if (noOfTransformation > 15) {
                toast.error("Cannot add preset, causing more than 15 transformations.");
                return {
                    ...state,
                };
            } else if (noOfTransformation == 15) {
                state.disableAddTransformation = true;
            }
            return {
                ...state,
                transformationList: preTransformationList,
            };
        case PlaygroundAction.ReplaceSelectedTransformation:
            return {
                ...state,
                transformationList: [
                    ...state.transformationList.slice(0, state.selectedTransformIdx),
                    payload.transformation,
                    ...state.transformationList.slice(state.selectedTransformIdx + 1),
                ],
                selectedTransformIdx: payload.transformation.isPreset
                    ? null
                    : state.selectedTransformIdx,
            };
        case PlaygroundAction.DeleteTransformationAtIdx:
            newState = {
                ...state,
                transformationList: state.transformationList.filter(
                    (val, idx) => payload.deleteIdx !== idx,
                ),
            };
            newState.disableAddTransformation =
                getTotalNumberofTransformation(newState.transformationList) < 15 ? false : true;
            if (state.transformationList.length === 1) {
                newState.previewUrl = state.originalUrl;
                newState.transformedUrl = state.originalUrl;
            }
            return newState;

        case PlaygroundAction.ClearState:
            return {
                ...initialState,
                /**
                 * We don't reset `formUrl` here, because if user returns to Playground, using browser's back button,
                 * we can use the previously stored `fromUrl` location to route user to on playground close.
                 */
                fromUrl: state.fromUrl,
            };
        case PlaygroundAction.StashTransformationList:
            return {
                ...state,
                transformationList: payload.newTransformationList,
                appliedAndPreviewedTransformationList: state.transformationList,
            };
        case PlaygroundAction.UnStashTransformationList:
            return {
                ...state,
                transformationList: state.appliedAndPreviewedTransformationList,
                appliedAndPreviewedTransformationList: [],
            };
        case PlaygroundAction.UnStashTrnsfrmtnListWithoutPreset:
            return {
                ...state,
                transformationList: state.appliedAndPreviewedTransformationList.filter(
                    (ele) => ele.presetName !== payload.presetName,
                ),
                appliedAndPreviewedTransformationList: [],
            };

        case PlaygroundAction.UpdateFromUrl:
            return {
                ...state,
                /**
                 * If `payload.fromUrl` is undefined or an empty string, it means we don't know where the user is coming from
                 * to Playground. This would mostly happen when the user uses browser's back button to return to playground.
                 * In such cases if we have a previously saved `fromUrl` we can route user to that location, instead of
                 * routing him to dashboard by setting `formUrl` as empty. This would improve the UX.
                 */
                fromUrl: payload.fromUrl || state.fromUrl,
            };

        case PlaygroundAction.UpdateConfig:
        case PlaygroundAction.UpdateOriginalUrl:
        case PlaygroundAction.UpdateTransformedUrl:
        case PlaygroundAction.UpdatePreviewUrl:
        case PlaygroundAction.UpdateEditDefaultTransformation:
        case PlaygroundAction.UpdateDefaultTransformation:
        case PlaygroundAction.UpdateSelectedTransformIdx:
        case PlaygroundAction.UpdatePresetData:
        case PlaygroundAction.ShowPreset:
        case PlaygroundAction.ShowTransformation:
        case PlaygroundAction.ChangePlaygroundTab:
            return {
                ...state,
                ...payload,
            };
        case PlaygroundAction.OriginalImageDetails:
            return {
                ...state,
                originalDetails: {
                    ...state.originalDetails,
                    ...payload,
                },
            };
        case PlaygroundAction.PreviewImageDetails:
            return {
                ...state,
                previewDetails: {
                    ...state.previewDetails,
                    ...payload,
                },
            };
        case PlaygroundAction.OriginalStatus:
            return {
                ...state,
                originalStatus: { ...state.originalStatus, ...payload },
            };
        case PlaygroundAction.TransformedStatus:
            return {
                ...state,
                transformedStatus: { ...state.transformedStatus, ...payload },
            };
        case PlaygroundAction.JsonStatus:
            return {
                ...state,
                jsonStatus: { ...state.jsonStatus, ...payload },
            };
        case PlaygroundAction.SetAppliedAndPreviewedTransformationList:
            newState = {
                ...state,
                ...payload,
            };
            return newState;

        default:
            return state;
    }
};

export default playgroundReducer;
