import VisaIcon from "common/assets/svgs/Visa.svg";
import AmexIcon from "common/assets/svgs/Amex.svg";
import DinnersIcon from "common/assets/svgs/Dinners Club.svg";
import MastercardIcon from "common/assets/svgs/Mastercard.svg";
import UnionPayIcon from "common/assets/svgs/UnionPay.svg";

export const CardIcons = {
    visa: VisaIcon,
    amex: AmexIcon,
    mastercard: MastercardIcon,
    diners: DinnersIcon,
    unionpay: UnionPayIcon,
    unknown: VisaIcon,
};
