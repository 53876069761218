import { SegmentAction } from "../actions/segmentActions";

const initialState = { isSegmentScriptInitiated: false };

const segmentReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    switch (type) {
        case SegmentAction.InitiateSegmentScipt:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default segmentReducer;
