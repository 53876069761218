import {
    buildDefaultFormData,
    updateFormData,
    createFormDataFromApiResponse,
} from "@common/utils/glamar-module-utils";
import { GlamarModuleActions } from "../actions/glamarModuleActions";
import JsonData from "../../../pages/BulkPlayground/glamar/common/data.json";

const initialBulkSkuUploadState = {
    uploadedFile: null,
    fileParseStatus: null,
    errorCSVData: "",
    totalBulkSkus: 0,
    failedItems: [],
    successItems: [],
    failedCount: 0,
    successCount: 0,
    payloadForPostApi: null,
};

const initialState = {
    formConfig: JsonData,
    formData: {},
    cachedFormData: {},
    uploadSuccess: false,
    uploadFailed: false,
    productsData: [],
    pageDetails: {},
    apiParams: {
        pageNo: { page: 1 },
        pageSize: 10,
        sortBy: "",
        sortOrder: "",
        searchBy: "",
        searchQuery: "",
    },
    clusteredPaginationData: [],
    skuToBeEdited: null,
    skuBulkUploadDetails: {
        ...initialBulkSkuUploadState,
    },
    bulkDeleteTotalSkus: [],
    showBulkNotificationPanel: false,
    isDeletingFilesFlag: false,

    showMobileBulkDeleteCheckbox: false,
    showCreditsExhaustedBanner: false,
};

let bulkSkuDetails = {};

const glamarModuleReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    switch (type) {
        case GlamarModuleActions.setFormBuilderJson:
            return {
                ...state,
                formConfig: payload,
            };
        case GlamarModuleActions.addDefaultFormData:
            let defaultFormData = {};
            defaultFormData = buildDefaultFormData(state);
            return {
                ...state,
                formData: defaultFormData,
                cachedFormData: defaultFormData,
            };
        case GlamarModuleActions.updateFormData:
            let updatedFormData = {};
            updatedFormData = updateFormData(state, payload);
            return {
                ...state,
                formData: updatedFormData,
            };
        case GlamarModuleActions.setFormData:
            return {
                ...state,
                formData: payload,
            };
        case GlamarModuleActions.setUploadSuccess:
            return {
                ...state,
                uploadSuccess: payload,
            };
        case GlamarModuleActions.setUploadFailed:
            return {
                ...state,
                uploadFailed: payload,
            };
        case GlamarModuleActions.setProductData:
            return {
                ...state,
                productsData: payload,
            };
        case GlamarModuleActions.setPageNumber:
            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    pageNo: {
                        ...state.apiParams.pageNo,
                        page: payload,
                    },
                },
            };
        case GlamarModuleActions.setPageSize:
            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    pageSize: payload,
                },
            };
        case GlamarModuleActions.setSortBy:
            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    sortBy: payload,
                },
            };
        case GlamarModuleActions.setSortOrder:
            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    sortOrder: payload,
                },
            };
        case GlamarModuleActions.setSearchBy:
            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    searchBy: payload,
                },
            };
        case GlamarModuleActions.setSearchQuery:
            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    searchQuery: payload,
                },
            };
        case GlamarModuleActions.setPageDetails:
            return {
                ...state,
                pageDetails: payload,
            };
        case GlamarModuleActions.setClusteredPaginationData:
            return {
                ...state,
                clusteredPaginationData: payload,
            };
        case GlamarModuleActions.setBulkDeleteTotalSkus:
            return {
                ...state,
                bulkDeleteTotalSkus: payload,
            };

        case GlamarModuleActions.setSkuToBeEdited:
            let newFormData = createFormDataFromApiResponse(payload);
            return {
                ...state,
                skuToBeEdited: payload,
                formData: newFormData,
                cachedFormData: newFormData,
            };
        case GlamarModuleActions.startSkuBulkUpload:
            bulkSkuDetails = { ...state.skuBulkUploadDetails };
            bulkSkuDetails = {
                ...bulkSkuDetails,
                uploadedFile: payload?.file,
                fileParseStatus: payload?.fileParseStatus,
            };
            return {
                ...state,
                skuBulkUploadDetails: {
                    ...bulkSkuDetails,
                },
            };
        case GlamarModuleActions.setFileParsingSuccess:
            bulkSkuDetails = { ...state.skuBulkUploadDetails };
            bulkSkuDetails = {
                ...bulkSkuDetails,
                totalBulkSkus: payload?.totalBulkSkus,
                fileParseStatus: payload?.fileParseStatus,
                payloadForPostApi: payload?.payload,
            };
            return {
                ...state,
                skuBulkUploadDetails: {
                    ...bulkSkuDetails,
                },
            };
        case GlamarModuleActions.setErrorWhileFileParsing:
            bulkSkuDetails = { ...state.skuBulkUploadDetails };
            bulkSkuDetails = {
                ...bulkSkuDetails,
                errorCSVData: `${payload?.errorCSVData}`,
                fileParseStatus: payload?.fileParseStatus,
            };
            return {
                ...state,
                skuBulkUploadDetails: {
                    ...bulkSkuDetails,
                },
            };
        case GlamarModuleActions.resetSkuBulkUpload:
            return {
                ...state,
                skuBulkUploadDetails: {
                    ...initialBulkSkuUploadState,
                },
            };
        case GlamarModuleActions.setEachBulkSkuSuccess:
            bulkSkuDetails = { ...state.skuBulkUploadDetails };
            bulkSkuDetails = {
                ...bulkSkuDetails,
                successCount: bulkSkuDetails?.successCount + 1,
                successItems: [...state.skuBulkUploadDetails.successItems, payload],
            };
            return {
                ...state,
                skuBulkUploadDetails: {
                    ...bulkSkuDetails,
                },
            };
        case GlamarModuleActions.setEachBulkSkuFailure:
            bulkSkuDetails = { ...state.skuBulkUploadDetails };
            bulkSkuDetails = {
                ...bulkSkuDetails,
                failedCount: bulkSkuDetails?.failedCount + 1,
                failedItems: [...state.skuBulkUploadDetails.failedItems, payload],
            };
            return {
                ...state,
                skuBulkUploadDetails: {
                    ...bulkSkuDetails,
                },
            };
        case GlamarModuleActions.setEachRetrySuccess:
            bulkSkuDetails = { ...state.skuBulkUploadDetails };
            bulkSkuDetails = {
                ...bulkSkuDetails,
                failedCount: bulkSkuDetails?.failedCount - 1,
                successCount: bulkSkuDetails?.successCount + 1,
                failedItems: [...payload.failedSavingSkusList],
                successItems: [...state.skuBulkUploadDetails.successItems, payload?.successItem],
            };
            return {
                ...state,
                skuBulkUploadDetails: {
                    ...bulkSkuDetails,
                },
            };
        case GlamarModuleActions.setBulkNotificationPanelVisibility:
            return {
                ...state,
                showBulkNotificationPanel: payload,
            };
        case GlamarModuleActions.setIsDeletingFilesFlag:
            return {
                ...state,
                isDeletingFilesFlag: payload,
            };

        case GlamarModuleActions.setShowMobileBulkDeleteCheckbox:
            return {
                ...state,
                showMobileBulkDeleteCheckbox: payload,
            };
        case GlamarModuleActions.setShowCreditsExhaustedBanner:
            return {
                ...state,
                showCreditsExhaustedBanner: payload,
            };
        default:
            return state;
    }
};

export default glamarModuleReducer;
