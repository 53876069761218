import { FILE_STATUS } from "@src/pages/BulkPlaygroundContext/playgroundUtils";
import { BulkPlaygroundAction } from "../actions/bulkPlaygroundActions";
import { v4 as uuid } from "uuid";

const initialState = {
    allowedPaths: ["new-playground"],
    isPathPresent: false,
    backgroundImages: [],
    backgroundColor: "",
    backgroundImage: "",
    removeText: false,
    removeLogo: false,
    upscaleTo1x: false,
    upscaleTo2x: true,
    upscaleTo4x: false,
    enhanceQuality: false,
    enhanceFace: false,
    storageBulkFolderData: null,
    selectedBatchId: null,
    activeBatchId: null,
    files: [],

    isBatchesLoading: true,
    batches: [],
    totalBatches: 0,
};

const initializeBatchData = (batch) => ({
    ...batch,
    _tempId: uuid(),
    isLoading: false,
});

const bulkPlaygroundReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    let batchIndex;
    switch (type) {
        case BulkPlaygroundAction.FileUploadQueued:
            batchIndex = state.batches.findIndex((item) => item._id === payload.batchId);
            if (batchIndex === -1) return state;

            const updatedFilesForQueued = [
                ...state.batches[batchIndex].files,
                {
                    name: payload.file.name,
                    size: payload.file.size,
                    status: FILE_STATUS.QUEUED,
                    _tempId: payload._tempId,
                    batchId: payload.batchId,
                },
            ];

            return {
                ...state,
                batches: [
                    ...state.batches.slice(0, batchIndex),
                    { ...state.batches[batchIndex], files: updatedFilesForQueued },
                    ...state.batches.slice(batchIndex + 1),
                ],
            };

        case BulkPlaygroundAction.FileUploadStarted:
            batchIndex = state.batches.findIndex((item) => item._id === payload.batchId);
            if (batchIndex === -1) return state;

            const updatedFilesForStarted = state.batches[batchIndex].files.map((file) => {
                if (file._tempId === payload._tempId) {
                    return { ...file, status: FILE_STATUS.UPLOAD_STARTED };
                }
                return file;
            });

            return {
                ...state,
                batches: [
                    ...state.batches.slice(0, batchIndex),
                    { ...state.batches[batchIndex], files: updatedFilesForStarted },
                    ...state.batches.slice(batchIndex + 1),
                ],
            };

        case BulkPlaygroundAction.FileUploadCompleted:
            batchIndex = state.batches.findIndex((item) => item._id === payload.batchId);
            if (batchIndex === -1) return state;

            const updatedFilesForCompleted = state.batches[batchIndex].files.map((file) => {
                if (file._tempId === payload._tempId) {
                    return {
                        ...file,
                        ...payload.fileData,
                        status: FILE_STATUS.UPLOAD_COMPLETED,
                    };
                }
                return file;
            });

            return {
                ...state,
                batches: [
                    ...state.batches.slice(0, batchIndex),
                    { ...state.batches[batchIndex], files: updatedFilesForCompleted },
                    ...state.batches.slice(batchIndex + 1),
                ],
            };

        case BulkPlaygroundAction.FileUpTrFailed:
            batchIndex = state.batches.findIndex((item) => item._id === payload.batchId);
            if (batchIndex === -1) return state;

            const updatedFilesForFailed = state.batches[batchIndex].files.map((file) => {
                if (file._tempId === payload._tempId) {
                    return { ...file, error: payload.error, status: FILE_STATUS.FAILED };
                }
                return file;
            });

            return {
                ...state,
                batches: [
                    ...state.batches.slice(0, batchIndex),
                    { ...state.batches[batchIndex], files: updatedFilesForFailed },
                    ...state.batches.slice(batchIndex + 1),
                ],
            };

        case BulkPlaygroundAction.FileTransformationStarted:
            batchIndex = state.batches.findIndex((item) => item._id === payload.batchId);
            if (batchIndex === -1) return state;

            const updatedFilesForTransformationStarted = state.batches[batchIndex].files.map(
                (file) => {
                    if (file._tempId === payload._tempId) {
                        return {
                            ...file,
                            status: FILE_STATUS.TRANSFORMATION_STARTED,
                            transformedUrl: payload.transformedUrl,
                            pattern: payload.pattern,
                        };
                    }
                    return file;
                },
            );

            return {
                ...state,
                batches: [
                    ...state.batches.slice(0, batchIndex),
                    { ...state.batches[batchIndex], files: updatedFilesForTransformationStarted },
                    ...state.batches.slice(batchIndex + 1),
                ],
            };

        case BulkPlaygroundAction.FileTransformationCompleted:
            batchIndex = state.batches.findIndex((item) => item._id === payload.batchId);
            if (batchIndex === -1) return state;

            const updatedFilesForTransformationCompleted = state.batches[batchIndex].files.map(
                (file) => {
                    if (file._tempId === payload._tempId) {
                        return {
                            ...file,
                            status: FILE_STATUS.TRANSFORMATION_COMPLETED,
                        };
                    }
                    return file;
                },
            );

            return {
                ...state,
                batches: [
                    ...state.batches.slice(0, batchIndex),
                    { ...state.batches[batchIndex], files: updatedFilesForTransformationCompleted },
                    ...state.batches.slice(batchIndex + 1),
                ],
            };

        case BulkPlaygroundAction.InitializeFilesFromDb: {
            const updatedFilesList = action.payload.files.map((file) => {
                if (file?.metadata?.batchId) {
                    const _tempId = uuid();
                    let fileStatus;
                    switch (file?.metadata?.file?.transformedStatus) {
                        case "complete":
                            fileStatus = FILE_STATUS.TRANSFORMATION_COMPLETED;
                            break;
                        case "failed":
                            fileStatus = FILE_STATUS.FAILED;
                            break;
                        default:
                            fileStatus = FILE_STATUS.UPLOAD_COMPLETED;
                            break;
                    }
                    return {
                        ...file,
                        _tempId,
                        batchId: file?.metadata?.batchId,
                        pattern: file?.metadata?.file?.pattern,
                        status: fileStatus,
                        ...(file?.metadata?.file?.transformedError && {
                            error: {
                                status: null,
                                statusText: file?.metadata?.file?.transformedError,
                            },
                        }),
                        transformedUrl: file?.metadata?.file?.transformedUrl,
                    };
                }

                if (file?.metadata) {
                    return {
                        ...file,
                        status: FILE_STATUS.UPLOAD_COMPLETED,
                        ...file?.metadata?.bulkPlaygroundData, // Flattens the metadata object into the file object
                    };
                }
                return file; // Return the file as is if it doesn't have metadata
            });
            return {
                ...state,
                files: updatedFilesList,
                activeBatchId: updatedFilesList?.[0]?.batchId,
                selectedBatchId: updatedFilesList?.[0]?.batchId,
            };
        }

        case BulkPlaygroundAction.UpdateSelectedBatch:
            return {
                ...state,
                selectedBatchId: payload.batchId,
                batches: state.batches.map((batch) => ({
                    ...batch,
                })),
            };

        case BulkPlaygroundAction.UpdateActiveBatch:
            return {
                ...state,
                activeBatchId: payload.batchId,
                batches: state.batches.map((batch) => ({
                    ...batch,
                })),
            };
        case BulkPlaygroundAction.ToggleBatchExpand:
            return {
                ...state,
                batches: state.batches.map((batch) => {
                    const payloadIsExpanded =
                        payload.isExpanded !== undefined ? payload.isExpanded : !batch.isExpanded;
                    return {
                        ...batch,
                        isExpanded:
                            batch._id === payload.batchId ? payloadIsExpanded : batch.isExpanded,
                    };
                }),
            };
        case BulkPlaygroundAction.DeleteFailedFile: {
            batchIndex = state.batches.findIndex((item) => item._id === payload.batchId);
            if (batchIndex === -1) return state; // If no matching batch is found, return the state unchanged

            const updatedFilesForDeletion = state.batches[batchIndex].files.filter((file) => {
                return !(file._tempId === payload._tempId && file.status === FILE_STATUS.FAILED);
            });

            return {
                ...state,
                batches: [
                    ...state.batches.slice(0, batchIndex),
                    { ...state.batches[batchIndex], files: updatedFilesForDeletion },
                    ...state.batches.slice(batchIndex + 1),
                ],
            };
        }
        case BulkPlaygroundAction.CancelActiveBatchUpload: {
            return {
                ...state,
                batches: state.batches.map((batch) => {
                    if (batch._id === payload.batchId) {
                        return {
                            ...batch,
                            files: batch.files.map((file) => {
                                if (file.status === FILE_STATUS.QUEUED) {
                                    return {
                                        ...file,
                                        status: FILE_STATUS.FAILED,
                                        error: payload.error,
                                    };
                                }
                                return { ...file };
                            }),
                        };
                    }
                    return batch;
                }),
            };
        }

        case BulkPlaygroundAction.AddBatch:
            return {
                ...state,
                batches: [
                    {
                        batchId: payload.batchId,
                        ...payload.folderData,
                        isLoading: false,
                        files: [],
                        isFilesFetched: true,
                    },
                    ...state.batches.map((batch) => ({
                        ...batch,
                    })),
                ],
                totalBatches: ++state.totalBatches,
            };

        case BulkPlaygroundAction.DeleteBatch:
            let newSelectedBatchId;
            let batchDataOnDelete;
            let newActiveBatchId;
            const batchToDeleteIndex = state.batches.findIndex(
                (batch) => batch._id === payload.batchId,
            );
            const newBatches = state.batches
                .filter((batch) => batch._id !== payload.batchId)
                .map((batch) =>
                    batch._id === newSelectedBatchId
                        ? {
                              ...batch,
                          }
                        : batch,
                );
            if (payload.batchId === state.selectedBatchId && batchToDeleteIndex !== -1) {
                const nextBatch = state.batches[batchToDeleteIndex + 1];
                const prevBatch = state.batches[batchToDeleteIndex - 1];

                if (nextBatch) {
                    newSelectedBatchId = nextBatch._id;
                    batchDataOnDelete = nextBatch;
                } else if (prevBatch) {
                    newSelectedBatchId = prevBatch._id;
                    batchDataOnDelete = prevBatch;
                } else {
                    newSelectedBatchId = null; // No batches remain after deletion.
                    batchDataOnDelete = null;
                }
            }
            if (newBatches?.[0]) newActiveBatchId = newBatches?.[0]?._id || null;
            return {
                ...state,
                batchDataOnDelete,
                batches: newBatches,
                totalBatches: state.totalBatches - 1,
                selectedBatchId: newSelectedBatchId ? newSelectedBatchId : state.selectedBatchId,
                activeBatchId:
                    state.activeBatchId === payload.batchId
                        ? newActiveBatchId
                        : state.activeBatchId,
            };

        case BulkPlaygroundAction.InitializeBatches:
            return {
                ...state,
                batches: payload.batches.map(initializeBatchData),
                totalBatches: payload.doesOldBatchFilesExist
                    ? ++payload.totalBatches
                    : payload.totalBatches,
                doesOldBatchFilesExist: payload.doesOldBatchFilesExist,
            };

        case BulkPlaygroundAction.InitializeOldBatchData:
            if (state.doesOldBatchFilesExist)
                return {
                    ...state,
                    batches: [...state.batches, payload.batch],
                };
            else return { ...state };

        case BulkPlaygroundAction.FetchBatches:
            return {
                ...state,
                batches: [...state.batches, ...payload.batches.map(initializeBatchData)],
            };
        case BulkPlaygroundAction.BatchesLoading:
            return {
                ...state,
                isBatchesLoading: payload.isBatchesLoading,
            };
        case BulkPlaygroundAction.BatchFilesLoading:
            return {
                ...state,
                batches: state.batches.map((batch) => {
                    if (batch._id === payload.batchId) {
                        return {
                            ...batch,
                            isLoading: payload.isLoading,
                        };
                    }
                    return batch;
                }),
            };
        case BulkPlaygroundAction.UpdateBatchFiles:
            const index = state.batches.findIndex((item) => item._id === payload.batchId);

            if (index === -1) return state;

            const updatedBatches = state.batches.map((item) => {
                if (item._id === payload.batchId) {
                    return {
                        ...item,
                        isFilesFetched: true,
                    };
                }
                return {
                    ...item,
                };
            });

            const updatedFiles = payload.files.map((file) => {
                const _tempId = uuid();
                let fileStatus;

                switch (file?.metadata?.file?.transformedStatus) {
                    case "complete":
                        fileStatus = FILE_STATUS.TRANSFORMATION_COMPLETED;
                        break;
                    case "failed":
                        fileStatus = FILE_STATUS.FAILED;
                        break;
                    default:
                        fileStatus = FILE_STATUS.UPLOAD_COMPLETED;
                        break;
                }
                return {
                    ...file,
                    _tempId,
                    batchId: payload?.batchId || file?.metadata?.batchId,
                    pattern: file?.metadata?.file?.pattern,
                    status: fileStatus,
                    ...(file?.metadata?.file?.transformationError && {
                        error: {
                            status: null,
                            statusText: file?.metadata?.file?.transformationError,
                        },
                    }),
                    transformedUrl: file?.metadata?.file?.transformedUrl,
                    ...file?.metadata?.bulkPlaygroundData,
                };
            });

            updatedBatches[index] = {
                ...updatedBatches[index],
                files: updatedFiles,
            };

            return {
                ...state,
                batches: updatedBatches,
            };

        case BulkPlaygroundAction.UpdateBackgroundColorStatus:
            return {
                ...state,
                backgroundColor: payload,
            };

        case BulkPlaygroundAction.UpdateBackgroundImageStatus:
            return {
                ...state,
                backgroundImage: payload,
            };

        case BulkPlaygroundAction.UpdateRemoveLogoStatus:
            return {
                ...state,
                removeLogo: payload,
            };

        case BulkPlaygroundAction.UpdateRemoveTextStatus:
            return {
                ...state,
                removeText: payload,
            };

        case BulkPlaygroundAction.UpdateIsPathPresent:
        case BulkPlaygroundAction.AddFiles:
        case BulkPlaygroundAction.AddBackgroundImages:
        case BulkPlaygroundAction.UpdateUpscaleStatus:
            return {
                ...state,
                ...payload,
            };

        case BulkPlaygroundAction.ClearState:
            return {
                ...initialState,
            };

        case BulkPlaygroundAction.UpdateStorageBulkFolder:
            return {
                ...initialState,
                ...payload,
            };

        default:
            return state;
    }
};

export default bulkPlaygroundReducer;
