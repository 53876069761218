import apiUrls from "../common/utils/apiUrls";
import ApiService from "./api.service";

const ZoneService = {
    getZones(orgId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${apiUrls.zones}?${queryString}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    getZone(orgId, zoneId) {
        const axiosOptions = {};
        return ApiService.get(
            apiUrls.zone.replace(":orgId", orgId).replace(":zoneId", zoneId),
            axiosOptions,
        );
    },
    createZone(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(apiUrls.zones.replace(":orgId", orgId), axiosOptions);
    },
    updateZone(orgId, zoneId, data) {
        const axiosOptions = { data };
        return ApiService.patch(
            apiUrls.zone.replace(":orgId", orgId).replace(":zoneId", zoneId),
            axiosOptions,
        );
    },
    deleteZone(orgId, zoneId) {
        const axiosOptions = {};
        return ApiService.delete(
            apiUrls.zone.replace(":orgId", orgId).replace(":zoneId", zoneId),
            axiosOptions,
        );
    },
    getQualityOptions(orgId) {
        const axiosOptions = {};
        return ApiService.get(apiUrls.zoneQualityOptions.replace(":orgId", orgId), axiosOptions);
    },
};

export default ZoneService;
