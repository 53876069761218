import React from "react";

export default function CheckboxInput({ label, type, register, validation, testId, ...restProps }) {
    return (
        <>
            <input
                data-testid={testId ? testId : "inputField"}
                {...(register && register(label, validation))}
                {...restProps}
                type={"checkbox"}
            ></input>
        </>
    );
}
