import { UserAgentAction } from "../actions/useragentAction";
let mobileTabletRegex = /android|webos|iphone|ipad|ipod|blackberry|mobile|tablet|kindle|iemobile/i;

const initialState = {
    isMobile: false,
    isFcWidgetLoaded: false,
    isNewUser: false,
    isReportIssueClicked: false,
};

const userAgentReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;
    switch (type) {
        case UserAgentAction.AddAgent:
            return {
                ...state,
                isMobile: mobileTabletRegex.test(payload),
            };
        case UserAgentAction.SetFcWidgetLoaded:
            return {
                ...state,
                isFcWidgetLoaded: payload,
            };
        case UserAgentAction.SetNewUser:
            let userId = payload?._id;
            if (userId) {
                let localData = JSON.parse(localStorage.getItem("users"));
                let userIdList = localData && Array.isArray(localData) ? localData : [];
                let isNewUser = Array.isArray(userIdList) && !userIdList.includes(userId);

                if (isNewUser) {
                    userIdList.push(userId);
                    localStorage.setItem("users", JSON.stringify(userIdList));
                }
                return {
                    ...state,
                    isNewUser: isNewUser,
                };
            }
            return state;
        case UserAgentAction.SetReportIssueClicked:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

export default userAgentReducer;
