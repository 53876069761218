import apiUrls from "../common/utils/apiUrls";
import ApiService from "./api.service";

const DatasourceService = {
    getDatasources(orgId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${apiUrls.datasources}?${queryString}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    getDatasource(orgId, datasourceId) {
        const axiosOptions = {};
        return ApiService.get(
            apiUrls.datasource.replace(":orgId", orgId).replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    getDatasourceSchemas(orgId) {
        const axiosOptions = {};
        return ApiService.get(apiUrls.datasourceSchemas.replace(":orgId", orgId), axiosOptions);
    },
    getDatasourceSchema(orgId, datasourceType) {
        const axiosOptions = {};
        return ApiService.get(
            apiUrls.datasourceSchema
                .replace(":orgId", orgId)
                .replace(":datasourceType", datasourceType),
            axiosOptions,
        );
    },
    createDatasource(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(apiUrls.datasources.replace(":orgId", orgId), axiosOptions);
    },
    updateDatasource(orgId, datasourceId, data) {
        const axiosOptions = { data };
        return ApiService.patch(
            apiUrls.datasource.replace(":orgId", orgId).replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    deleteDatasource(orgId, datasourceId) {
        const axiosOptions = {};
        return ApiService.delete(
            apiUrls.datasource.replace(":orgId", orgId).replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    getItems(
        orgId,
        path,
        onlyFiles,
        onlyFolders,
        sortBy,
        sortAscending,
        datasourceId,
        pageNo = 1,
        pageSize = 100,
        nextPageToken = "",
    ) {
        const axiosOptions = {
            params: {
                sort: sortAscending ? sortBy : "-" + sortBy,
                onlyFiles,
                onlyFolders,
                pageNo,
                pageSize,
                nextPageToken,
                path,
            },
        };

        return ApiService.get(
            apiUrls.datasourceAssets
                .replace(":orgId", orgId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    searchItems(
        orgId,
        path,
        searchQuery,
        onlyFiles,
        onlyFolders,
        sortBy,
        sortAscending,
        datasourceId,
        pageNo = 1,
        pageSize = 100,
        nextPageToken = "",
    ) {
        const axiosOptions = {
            params: {
                sort: sortAscending ? sortBy : "-" + sortBy,
                onlyFiles,
                onlyFolders,
                pageNo,
                pageSize,
                nextPageToken,
                path,
                searchQuery,
            },
        };

        return ApiService.get(
            apiUrls.datasourceAssets
                .replace(":orgId", orgId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
};

export default DatasourceService;
