import { DomainAction } from "../actions/domainActions";

const initialState = { domains: {}, searchResults: null, pages: {} };

const domainReducer = (state = initialState, action = { type: "", payload: {} }) => {
    const { type, payload } = action;

    switch (type) {
        case DomainAction.GetDomains:
            return {
                ...state,
                ...payload,
            };
        case DomainAction.DomainCreateSuccess:
        case DomainAction.DomainCreateFailure:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default domainReducer;
