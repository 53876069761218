import React, { lazy, Suspense, useEffect } from "react";
import useRedirectUrl from "@common/hooks/useRedirectUrl";
import { hot } from "react-hot-loader";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import Loader from "./common/components/Loader";
import PrivateRoute from "./common/components/PrivateRoute";
import ReportIssue from "./common/components/ReportIssue";
import ScrollToTop from "./common/components/ScrollToTop";
import ToastNotification from "./common/components/ToastNotification";
import { BreakpointProvider } from "./common/hooks/Breakpoint";
import useScriptsHooks from "./common/hooks/useScriptsHooks";
import { CropContextProvider } from "./pages/Playground/CropContext";
import routes from "./routes";
import UserService from "./services/user.service";
import useGoogleOneTapImpressions from "@common/hooks/useGoogleOneTapImpressions";
import { GTMContextProvider } from "@common/components/GTM/GTMContext";
import { ResizeContextProvider } from "./pages/Playground/ResizeContext";
import { LOAD_FRESHCHAT } from "@common/events/events";
import { EventEmitter } from "@common/events/eventEmitter";

const Header = lazy(() => import("./common/components/Header"));

const Fallback = styled.div`
    height: 100%;
    background: var(--bg-color);
`;

const Wrapper = styled.div`
    height: 100%;
    overflow: hidden;
`;

const ComponentWrapper = styled.div`
    overflow: auto;
    margin-top: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
        isUserLoggedIn && showTopNavbar ? (showBanner ? `${bannerHeight + 64}px` : "64px") : "0px"};
    height: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
        isUserLoggedIn && showTopNavbar
            ? showBanner
                ? `calc(100% - ${bannerHeight + 64}px)`
                : "calc(100% - 64px)"
            : "100%"};
`;

const ModalRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1003;
`;

const SheetRoot = styled.div`
    position: fixed;
    z-index: 999;
`;

const CropRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1004;
`;

const ResizeRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1004;
`;

const JavascriptEditor = styled.div`
    position: fixed;
    top: "0";
    left: "0";
    z-index: 1003;
`;

const App = () => {
    useScriptsHooks();
    useRedirectUrl();
    useGoogleOneTapImpressions();
    const scrollTopVisible = useSelector((state) => state.uploadDetails.scrollTopVisible);
    const showTopNavbar = useSelector((state) => state.menuDetails.showTopNavbar);
    const isLoaderOverlayVisible = useSelector((state) => state.menuDetails.isLoaderOverlayVisible);
    const { isUserLoggedIn } = useSelector((state) => state.authenticationDetails);
    const { showBanner } = useSelector((state) => state.menuDetails.showBanner);
    const bannerHeight = useSelector((state) => state.menuDetails.bannerHeight);

    useEffect(() => {
        if (isUserLoggedIn) {
            EventEmitter.dispatch(LOAD_FRESHCHAT);
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        UserService.getCurrentUserSession()
            .then((response) => {
                const message = {
                    type: "sessionData",
                    data: response.data,
                };
                window.parent.postMessage(JSON.stringify(message), "*");
            })
            .catch((error) => {
                const message = {
                    type: "sessionData",
                    data: error.response.data,
                };
                window.parent.postMessage(JSON.stringify(message), "*");
            });
    }, []);

    return (
        <CropContextProvider>
            <ResizeContextProvider>
                <Wrapper data-testid="app">
                    <BrowserRouter>
                        <BreakpointProvider>
                            <GTMContextProvider>
                                <ThemeProvider theme={{ scrollTopVisible }}>
                                    <ScrollToTop />
                                    <Suspense fallback={<Fallback />}>
                                        <Header />
                                    </Suspense>
                                    <ComponentWrapper
                                        id="componentWrapper"
                                        isUserLoggedIn={isUserLoggedIn}
                                        showTopNavbar={showTopNavbar}
                                        showBanner={showBanner}
                                        bannerHeight={bannerHeight}
                                    >
                                        <Suspense fallback={<Fallback />}>
                                            <Switch>
                                                {routes.map((route, index) => (
                                                    <Route path={route.path} key={index}>
                                                        <PrivateRoute
                                                            component={route.component}
                                                            guard={route.guard}
                                                            path={route.path}
                                                        />
                                                    </Route>
                                                ))}
                                            </Switch>
                                        </Suspense>
                                    </ComponentWrapper>
                                </ThemeProvider>
                            </GTMContextProvider>
                        </BreakpointProvider>
                        <ReportIssue />
                    </BrowserRouter>
                    <ToastNotification />
                    {isLoaderOverlayVisible && <Loader />}
                    <ModalRoot id="modal-root" />
                    <SheetRoot id="sheet-root" />
                    <CropRoot id="crop-root" />
                    <ResizeRoot id="resize-root" />
                    <JavascriptEditor id="javascript-editor" />
                </Wrapper>
            </ResizeContextProvider>
        </CropContextProvider>
    );
};

export default hot(module)(App);
