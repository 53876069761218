import UserService from "services/user.service";
import store from "../common/redux/store";
import { AuthAction, setRedirectUrl } from "../common/redux/actions/authActions";
import { MenuAction } from "@common/redux/actions/menuActions";
import { ORG_ROUTE_REGEX } from "@common/constants/constants";
import QueryBuilder from "./Querybuilder";
import { normalizeQueryParam, setRedirectTo } from "@common/utils/common-utils";

const AuthGuard = async () => {
    const storeState = store.getState();
    const isEmailVerificationPath = window.location.pathname.startsWith("/auth/emailVerification");
    const isAuthPath = window.location.pathname.includes("auth");
    const currentURL = window.location.pathname + window.location.search + window.location.hash;

    const queryBuilder = new QueryBuilder(window.location.origin + currentURL);

    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get("utm_source");
    const redirectUrlParam = normalizeQueryParam(queryParams.get("redirectUrl"));
    const redirectURL = redirectUrlParam || (currentURL.match(ORG_ROUTE_REGEX) ? currentURL : null);

    const redirectTo = queryParams.get("redirectTo");
    if (redirectTo) {
        setRedirectTo(redirectTo);
    }

    if (storeState["authenticationDetails"]["user"]) {
        if (isEmailVerificationPath) {
            store.dispatch({
                type: MenuAction.SetShowTopNavBar,
                payload: { showTopNavBar: false },
            });
            return "";
        } else if (isAuthPath) {
            return `/choose-org${queryBuilder.toString()}`;
        } else {
            return "";
        }
    } else {
        try {
            /**
             * Any `console.pixelbin.io` route having `?__token__=<<<bg.session cookie value>>>` as query params will make a call to `POST /token` API for verifying session.
             * This helps user to directly land on the page they want to access.
             * Built for simplifying payment flow for users coming from mobile app.
             * Context JIRA : PIXB-3757
             */
            const sessionToken = queryParams.get("__token__");
            if (sessionToken) {
                await UserService.verfiySessionToken(sessionToken);
            }
        } catch (error) {
            console.error(error);
        }
        try {
            const res = await UserService.getCurrentUserSession();
            store.dispatch({
                type: AuthAction.CheckUserSession,
                payload: {
                    isUserLoggedIn: true,
                    user: res.data.session.passport.user,
                },
            });

            if (isEmailVerificationPath) {
                store.dispatch({
                    type: MenuAction.SetShowTopNavBar,
                    payload: { showTopNavBar: false },
                });
                return "";
            }

            if (isAuthPath) {
                if (
                    utmSource &&
                    !queryParams.get("redirectToPixelbinPropertyFlow") &&
                    !redirectURL
                ) {
                    queryBuilder.append("redirectToFreePropertyFlow", "true");
                }
                return `/choose-org?${queryBuilder.toString()}`;
            }
            return "";
        } catch (err) {
            console.error(err);
            setRedirectUrl({ redirectUrl: redirectURL }, store.dispatch);
            if (isAuthPath) {
                if (queryParams.get("redirectToPixelbinPropertyFlow")) {
                    return "";
                }

                if (utmSource) {
                    if (!redirectURL) {
                        queryBuilder.append("redirectToPixelbinPropertyFlow", "true");
                    }
                    return {
                        location: window.location.pathname,
                        search: `?${queryBuilder.toString()}`,
                    };
                }

                return "";
            }
            return redirectURL
                ? `/auth/login?redirectUrl=${encodeURIComponent(redirectURL)}`
                : "/auth/login";
        }
    }
};

export default AuthGuard;
