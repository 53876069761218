export const BulkPlaygroundAction = {
    UpdateIsPathPresent: "UPDATE_IS_PATH_PRESENT",
    UpdateBackgroundColorStatus: "UPDATE_BACKGROUND_COLOR_STATUS",
    UpdateBackgroundImageStatus: "UPDATE_BACKGROUND_IMAGE_STATUS",
    AddBackgroundImages: "ADD_BACKGROUND_IMAGES",
    UpdateRemoveTextStatus: "UPDATE_REMOVE_TEXT_STATUS",
    UpdateRemoveLogoStatus: "UPDATE_REMOVE_LOGO_STATUS",
    UpdateUpscaleStatus: "UPDATE_UPSCALE_STATUS",
    ClearState: "CLEAR_STATE",
    UpdateStorageBulkFolder: "UPDATE_STORAGE_BULK_FOLDER",
    FileUploadQueued: "FILE_UPLOAD_QUEUED",
    FileUploadStarted: "FILE_UPLOAD_STARTED",
    FileUploadCompleted: "FILE_UPLOAD_COMPLETED",
    FileTransformationStarted: "FILE_TRANSFORMATION_STARTED",
    FileTransformationCompleted: "FILE_TRANSFORMATION_COMPLETED",
    FileUpTrFailed: "FILE_UP_TR_FAILED",
    InitializeFilesFromDb: "INITIALIZE_FILES_FROM_DB",
    UpdateSelectedBatch: "UPDATE_SELECTED_BATCH",
    UpdateActiveBatch: "UPDATE_ACTIVE_BATCH",
    DeleteFailedFile: "DELETE_FAILED_FILE",
    CancelActiveBatchUpload: "CANCEL_ACTIVE_BATCH_UPLOAD",

    AddBatch: "ADD_BATCH",
    InitializeBatches: "INITIALIZE_BATCHES",
    UpdateBatchFiles: "UPDATE_BATCH_FILES",
    FetchBatches: "FETCH_BATCHES",
    DeleteBatch: "DELETE_BATCH",
    InitializeOldBatchData: "INITIALIZE_OLD_BATCH_DATA",
    BatchFilesLoading: "BATCH_FILES_LOADING",
    BatchesLoading: "BATCHES_LOADING",
    ToggleBatchExpand: "TOGGLE_BATCH_EXPAND",
};

export const updateIsPathPresent = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateIsPathPresent,
        payload: data,
    });
};

export const updateBackgroundColorStatus = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateBackgroundColorStatus,
        payload: data,
    });
};

export const updateBackgroundImageStatus = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateBackgroundImageStatus,
        payload: data,
    });
};

export const addBackgroundImages = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.AddBackgroundImages,
        payload: data,
    });
};

export const updateRemoveLogoStatus = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateRemoveLogoStatus,
        payload: data,
    });
};

export const updateRemoveTextStatus = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateRemoveTextStatus,
        payload: data,
    });
};

export const updateUpscaleStatus = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateUpscaleStatus,
        payload: data,
    });
};

export const clearState = (dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.ClearState,
    });
};

export const updateStorageBulkFolder = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateStorageBulkFolder,
        payload: data,
    });
};

export const fileUploadQueued = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.FileUploadQueued,
        payload: data,
    });
};
export const fileUploadStarted = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.FileUploadStarted,
        payload: data,
    });
};
export const fileUploadCompleted = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.FileUploadCompleted,
        payload: data,
    });
};
export const fileTransformationStarted = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.FileTransformationStarted,
        payload: data,
    });
};
export const fileTransformationCompleted = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.FileTransformationCompleted,
        payload: data,
    });
};
export const fileUpTrFailed = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.FileUpTrFailed,
        payload: data,
    });
};

export const initializeFilesFromDb = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.InitializeFilesFromDb,
        payload: data,
    });
};

export const updateSelectedBatch = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateSelectedBatch,
        payload: data,
    });
};

export const updateActiveBatch = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateActiveBatch,
        payload: data,
    });
};

export const deleteFailedFile = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.DeleteFailedFile,
        payload: data,
    });
};

export const cancelActiveBatchUpload = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.CancelActiveBatchUpload,
        payload: data,
    });
};

export const addBatch = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.AddBatch,
        payload: data,
    });
};

export const deleteBatch = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.DeleteBatch,
        payload: data,
    });
};

export const initializeBatches = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.InitializeBatches,
        payload: data,
    });
};

export const fetchBatches = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.FetchBatches,
        payload: data,
    });
};

export const updateBatchFiles = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.UpdateBatchFiles,
        payload: data,
    });
};

export const initializeOldBatchData = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.InitializeOldBatchData,
        payload: data,
    });
};

export const batchFilesLoading = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.BatchFilesLoading,
        payload: data,
    });
};

export const batchesLoading = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.BatchesLoading,
        payload: data,
    });
};

export const toggleBatchExpand = (data, dispatch) => {
    dispatch({
        type: BulkPlaygroundAction.ToggleBatchExpand,
        payload: data,
    });
};
