import apiUrls from "common/utils/apiUrls";
import ApiService from "./api.service";
import urljoin from "url-join";

const ConsentService = {
    async getLatestPrivacyPolicyVersion() {
        const response = await ApiService.get(
            urljoin(apiUrls.privacyPolicy, `?locale=en&searchTag=pixelbin`),
        );
        const { versionNumber } = response.data;
        return versionNumber.toString();
    },

    getUserConsent() {
        let axiosOptions = { params: { property: "pixelbin" } };
        return ApiService.get(apiUrls.consent, axiosOptions);
    },

    updateConsent(consent) {
        let axiosOptions = {
            params: { property: "pixelbin" },
            data: { ...consent },
        };
        return ApiService.patch(apiUrls.consent, axiosOptions);
    },
};

module.exports = ConsentService;
